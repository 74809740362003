<!-- TodoForm.vue -->
<template>
  <div>
    <div v-if="loading">
      <v-progress-circular
        style="position: fixed; left: calc(50% - 40px); top: calc(50% - 40px)"
        :size="80"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-textarea
        ref="foodtitle"
        v-model="data.title"
        required
        auto-grow
        placeholder="title"
        rows="1"
        style="font-size: 14pt; font-weight: 800"
      ></v-textarea>

      <v-row>
        <v-col>
          <v-select dense outlined label="Orden" v-model="data.order" :items="orderOptions" hide-details></v-select>
        </v-col>
        <v-col>
          <v-select
            dense
            outlined
            label="Ideal para"
            v-model="data.meal"
            :items="mealOptions"
            hide-details
            multiple
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-select dense outlined label="Tipo" v-model="data.content" :items="contentOptions" hide-details></v-select>
        </v-col>
      </v-row>

      <v-checkbox v-model="data.ana" label="Ana"></v-checkbox>

      <v-textarea
        outlined
        auto-grow
        rows="3"
        label="Ingredientes"
        v-model="data.ingredients"
        @change="formatIngredients()"
      ></v-textarea>

      <div>
        <v-chip
          v-for="tag in tags"
          :key="tag"
          small
          :outlined="!data.tags || !data.tags.includes(tag)"
          class="ml-1"
          @click="toggleTag(tag)"
          >{{ tag }}</v-chip
        >
      </div>

      <v-textarea
        v-if="false"
        outlined
        auto-grow
        rows="1"
        label="Descripción corta"
        v-model="data.description"
      ></v-textarea>

      <div style="margin-top: 20px">
        <b>Preparación</b>
        <TextEditor v-model="data.receta" />
      </div>

      <!-- footer -->

      <div style="height: 80px"></div>

      <div
        style="
          position: fixed;
          height: 60px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          border-top: 1px solid #ccc;
          background-color: white;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>
          <v-btn text color="primary" @click="back">cancel</v-btn>
          <v-btn text color="error" @click="deleteItem">delete</v-btn>
        </div>
        <div>
          <v-btn text color="success" @click="save">save</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'

import TextEditor from '@/components/TextEditor2.vue'

export default {
  components: { TextEditor },
  props: {
    id: {
      type: String,
      default: '',
    },
    isnew: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id() {
      this.fetchData()
    },
  },
  computed: {},
  data() {
    return {
      loading: false,
      data: {
        id: '',
        title: '',
        description: '',
        type: '',
        order: '',
        meal: '',
        ana: '',
        tags: [],
        receta: '',
      },
      contentOptions: [
        { text: 'hidratos', value: 'hidratos' },
        { text: 'verdura', value: 'verdura' },
        { text: 'carne', value: 'carne' },
        { text: 'legumbres', value: 'legumbres' },
        { text: 'pescado', value: 'pescado' },
        { text: 'proteínas', value: 'proteinas' },
      ],
      orderOptions: [
        { text: 'primero', value: 'primero' },
        { text: 'segundo', value: 'segundo' },
        { text: 'plato único', value: 'plato_unico' },
        { text: 'postre', value: 'postre' },
      ],
      mealOptions: [
        { text: 'comida', value: 'comida' },
        { text: 'cena', value: 'cena' },
        { text: 'desayuno', value: 'desayuno' },
        { text: 'aperitivo', value: 'aperitivo' },
        { text: 'copas', value: 'copas' },
      ],
      tags: ['fácil', 'rápido', 'sano', 'elaborado', 'rico', 'fiesta', 'tupper-friendly', 'instant-pot'],
    }
  },
  methods: {
    formatIngredients() {
      console.log('xxxxx', 'a')
      let lines = this.data.ingredients.split(/\n/)
      let lines2 = []

      let n = 0
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i]
        line = line.match(/^(\d+\. )?(.*)/)?.[2]
        if (line) {
          n++
          line = n + '. ' + line
        }
        lines2.push(line)
      }
      this.data.ingredients = lines2.join('\n')
    },
    toggleTag(tag) {
      if (!this.data.tags) this.data.tags = []
      let i = this.data.tags.findIndex((x) => x == tag)

      if (i == -1) {
        this.data.tags.push(tag)
      } else {
        this.data.tags.splice(i, 1)
      }
    },
    async fetchData() {
      console.log('fetchgh', this.id)
      if (this.isnew) this.$refs.foodtitle.focus()
      this.data = { tags: [] }
      if (!this.id) {
        console.log('error, expeced id')
        return
      }
      this.loading = true
      let x = await api.apiFetch('/food/get/' + this.id)

      if (x.ok) {
        let j = await x.json()
        if (j && j.id) {
          //existing item
          if (!j.tags) j.tags = []
          this.data = j
        } else {
          //new item
          this.data = { tags: [] }
          this.data.id = this.id
        }
      } else {
        this.data = {}
        this.data.id = this.id
        this.data.title = ''
      }

      this.loading = false
    },
    back() {
      this.$router.go(-1)
    },
    async save() {
      let x = await api.apiFetch('/food/put', {
        method: 'POST',
        body: JSON.stringify(this.data),
      })
      if (x.ok) {
        this.$emit('saved')
        this.back()
      } else {
        alert('Ouch. Not saved')
      }
    },
    async deleteItem() {
      let sure = confirm('You sure?')
      if (!sure) return
      let x = await api.apiFetch('/food/delete/' + this.id, {
        method: 'POST',
      })
      if (x.ok) {
        this.$emit('saved') //is the same upstream kind of
        this.back()
      }
    },
  },
  async mounted() {
    await this.fetchData()
  },
}
</script>

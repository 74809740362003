const urlParametersAux = {
  params: () => {
    return urlParameters(window.location)
  },
  remove: (param) => {
    let p = urlParameters(window.location)
    if (p[param]) delete p[param]
    window.location = upateUrlWithParameters(window.location, p)
  },
  update: (param, value) => {
    let p = urlParameters(window.location)
    p[param] = value
    window.location = upateUrlWithParameters(window.location, p)
  },
}

/**
 *
 * @param {object} url
 */
const urlParameters = (url) => {
  //custom function
  const u = decodeURIComponent(url) // String(url)

  const p1 = u.split('?')[1]
  if (!p1) return {}

  let x = {}
  for (let i = 0; i < p1.split('&').length; i++) {
    const p = p1.split('&')[i]
    const k = p.split('=')[0]
    const v = p.split('=')[1]
    x[k] = v
  }

  return x
}
const upateUrlWithParameters = (url, params) => {
  const pureUrl = String(url).split('?')[0]
  let qs = []
  for (let i = 0; i < Object.keys(params).length; i++) {
    const p = Object.keys(params)[i]
    const v = params[p]
    qs.push(p + '=' + v)
  }

  if (qs.length == 0) return pureUrl
  else return pureUrl + '?' + qs.join('&')
}

module.exports = { urlParametersAux }

<template>
  <div style="max-width: 500px; margin: auto">
    <h1>
      Menus

      <span @click="date = new Date().toISOString().substring(0, 10)" class="modern-link">Today</span>

      <div style="display: flex; justify-content: center; align-items: center"></div>
    </h1>

    <!-- calendar -->

    <div style="position: relative">
      <!-- TODO: Add a loading somthing v-if="loadingMonth" -->
      <div
        v-if="loadingMonth"
        style="
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 99;
          background-color: grey;
          opacity: 0.1;
        "
      >
        <v-progress-circular
          v-if="false"
          style="z-index: 999; padding: 50%; margin: 10px"
          :size="10"
          width="2"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-date-picker v-model="date" :events="events" :first-day-of-week="1" no-title full-width></v-date-picker>
    </div>

    <!-- menu of the given date -->
    <div>
      <h3>
        {{ dateTitle(date) }}
        <span class="modern-link" @click="random7d()">Fill next 7 days</span>
      </h3>

      <div v-if="loading && false" style="width: 100%; text-align: center; padding-top: calc(50% - 60px)">
        <v-progress-circular v-if="false" :size="80" indeterminate></v-progress-circular>
      </div>

      <div v-else>
        <!-- comida -->
        <div>
          <b>Comida</b>
          <FoodLineItem
            :value="menu.comida_primero"
            meal="comida"
            order="primero"
            :food="food"
            @change="updateFood('comida_primero', $event)"
            :loading="loading"
          />
          <FoodLineItem
            :value="menu.comida_segundo"
            meal="comida"
            order="segundo"
            :food="food"
            :loading="loading"
            @change="updateFood('comida_segundo', $event)"
          />
        </div>
        <!-- cena -->
        <div>
          <b>Cena</b>
          <FoodLineItem
            :value="menu.cena_primero"
            meal="cena"
            order="primero"
            :food="food"
            :loading="loading"
            @change="updateFood('cena_primero', $event)"
          />
          <FoodLineItem
            :value="menu.cena_segundo"
            meal="cena"
            order="segundo"
            :food="food"
            :loading="loading"
            @change="updateFood('cena_segundo', $event)"
          />
        </div>
      </div>
    </div>

    <!-- buttons to swtich day fast-->
    <div style="display: flex; justify-content: space-between; margin-top: 10px">
      <v-btn outlined color="primary" @click="date = new Date().toISOString().substring(0, 10)">Today</v-btn>
      <div style="display: flex; justify-content: space-between">
        <v-btn outlined color="primary" @click="addDays(-1)" class="mr-3">-1</v-btn>
        <v-btn outlined color="primary" @click="addDays(1)">+1</v-btn>
      </div>
    </div>
    <!-- shopping list-->
    <div>
      <v-btn depressed @click="buildShoppingList()">Shopping List next 7 days</v-btn>

      <div>
        <div v-for="(si, i) in shoppingList" :key="i">
          {{ si }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'

import FoodLineItem from './FoodLineItem.vue'

export default {
  components: { FoodLineItem },
  watch: {
    date() {
      this.fetchDate()
    },
    month() {
      this.fetchMonth()
    },
  },
  data() {
    return {
      date: new Date().toISOString().substring(0, 10),
      loading: false,
      loadingMonth: false,
      menu: { comida_primero: null, comida_segundo: null, cena_primero: null, cena_segundo: null },
      menus: [],
      food: [],
      showComidaPicker: false,
      showCenaPicker: false,
      comida_id: '',

      shoppingList: [],
    }
  },
  computed: {
    month() {
      return new Date(this.date).getMonth()
    },
  },
  methods: {
    buildShoppingList() {
      let d1 = new Date(this.date)
      let d2 = new Date(this.date)
      d2.setDate(d2.getDate() + 7)

      console.log(this.date, d1, d2)

      let ms = this.menus.filter((x) => new Date(x.date) >= new Date(d1) && new Date(x.date) < new Date(d2))

      console.log('ms', ms)

      let list = []

      for (let i = 0; i < ms.length; i++) {
        let sl = this.menuShoppingList(ms[i])
        list = [...list, ...sl]
      }
      this.shoppingList = [...list].sort()
    },
    menuShoppingList(menu) {
      let meals = ['comida_primero', 'comida_segundo', 'cena_primero', 'cena_segundo']
      let list = []
      for (let j = 0; j < meals.length; j++) {
        let id = menu[meals[j]]
        let f = this.food.find((x) => x.id == id)
        let ings = f.ingredients ? f.ingredients.split(/\n/g) : []
        for (let k = 0; k < ings.length; k++) {
          let ing = ''
          ing = ings[k]
          ing = ing.replaceAll(/^\d+\. */g, '')
          list.push(ing)
        }
      }
      return list
    },
    randomItem(array) {
      let randomIndex = Math.floor(Math.random() * array.length)
      return array[randomIndex]
    },
    menuCandidates(meal, order) {
      return this.food.filter((x) => x.meal.includes(meal) && order.includes(x.order))
    },
    randomMenu(meal, order) {
      let candidates = this.menuCandidates(meal, order)
      let ritem = this.randomItem(candidates)
      if (order.includes('primerooo')) {
        console.log('candidates', candidates)
        console.log("'random item'", ritem.title)
      }
      return ritem
    },
    async random7d() {
      this.loading = true

      let ndays = 7
      for (let i = 0; i < ndays; i++) {
        let d = new Date(this.date)
        d.setDate(d.getDate() + i)

        const comida1 = this.randomMenu('comida', ['primero', 'plato_unico'])
        const comida2 = comida1.order == 'plato_unico' ? null : this.randomMenu('comida', ['segundo'])
        const cena1 = this.randomMenu('cena', ['primero', 'plato_unico'])
        const cena2 = cena1.order == 'plato_unico' ? null : this.randomMenu('cena', ['segundo'])

        let date = d.toISOString().substring(0, 10)
        let menu = {
          date,
          comida_primero: comida1,
          comida_segundo: comida2,
          cena_primero: cena1,
          cena_segundo: cena2,
        }

        let menuApi = this.collapseItem(menu)
        console.warn(date, menuApi)

        await this.saveOneMenu(menuApi)

        //this.saveOneMenu(menuApi)
      }
      await this.fetchMonth()
      await this.fetchDate()
      this.loading = false

      this.fetchMonth()
      //alert('Done! I added menus to the selected date and the following next 7')
    },
    dateTitle(date) {
      let d = new Date(date).getDay()
      let w = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'][d]
      return w + ' ' + new Date(date).getDate()
    },
    addDays(days) {
      let d = new Date(this.date)
      d.setDate(d.getDate() + days)
      this.date = d.toISOString().substring(0, 10)
    },
    events(date) {
      if (this.loadingMonth) return []
      let e = []
      for (let i = 0; i < this.menus.length; i++) {
        const menu = this.menus[i]
        if (menu.date == date) {
          if (menu.comida_primero) e.push('blue')
          if (menu.cena_primero) e.push('green')
        }
      }
      return e
    },
    async updateFood(meal, id) {
      this.menu[meal] = await this.fetchOneFood(id)
      this.saveTheDate()
    },

    async saveOneMenu(menuApi) {
      let x = await api.apiFetch('/food/menus/put', {
        method: 'POST',
        body: JSON.stringify(menuApi),
      })
      this.loading = false
      if (x.ok) {
        console.log('saved successfully menu for date ' + menuApi.date + '!')
      } else {
        console.error('Ouch. Error saving menu for ' + menuApi.date)
      }
    },

    async saveTheDate() {
      let final = this.collapseItem(this.menu)
      this.loading = true
      await this.saveOneMenu(final)
      this.loading = false
      await this.fetchMonth()
    },
    collapseItem(menu) {
      let x = {
        date: menu.date,
        comida_primero: menu.comida_primero ? menu.comida_primero.id : '',
        comida_segundo: menu.comida_segundo ? menu.comida_segundo.id : '',
        cena_primero: menu.cena_primero ? menu.cena_primero.id : '',
        cena_segundo: menu.cena_segundo ? menu.cena_segundo.id : '',
      }
      return x
    },
    async expandItem(menu) {
      let x = {
        date: this.date,
        comida_primero: await this.fetchOneFood(menu.comida_primero),
        comida_segundo: await this.fetchOneFood(menu.comida_segundo),
        cena_primero: await this.fetchOneFood(menu.cena_primero),
        cena_segundo: await this.fetchOneFood(menu.cena_segundo),
      }
      return x
    },
    async fetchDate() {
      let loadedMenu = this.menus.find((x) => x.date == this.date)
      if (loadedMenu) {
        this.menu = await this.expandItem(loadedMenu)
        return
      }

      this.loading = true
      let date = this.date
      let x = await api.apiFetch('/food/menus/query?start=' + date + '&end=' + date)
      if (x.ok) {
        let result = await x.json()
        if (!result || !result.items || !result.items.length) {
          this.menu = await this.expandItem({})
        } else {
          this.menu = await this.expandItem(result.items[0])
        }
      }
      this.loading = false
    },
    async fetchOneFood(foodId) {
      if (!foodId) return
      let f1 = this.food.find((x) => x.id == foodId)
      if (f1) return f1

      let x = await api.apiFetch('/food/get/' + foodId)
      if (x.ok) {
        let data = await x.json()
        return data
      }
    },
    async fetchAllFood() {
      this.loading = true
      let x = await api.apiFetch('/food/getall')
      if (x.ok) {
        this.food = await x.json()
      }
      this.loading = false
    },
    async fetchMonth() {
      this.loadingMonth = true
      let date = new Date(this.date)
      let start = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10)
      let end = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substring(0, 10)
      let x = await api.apiFetch('/food/menus/query?start=' + start + '&end=' + end)
      if (x.ok) {
        let data = await x.json()
        this.menus = [...data.items]
      }
      this.loadingMonth = false
    },
  },
  mounted() {
    this.fetchDate()
    this.fetchMonth()
    this.fetchAllFood()
  },
}
</script>

<style lang="scss" scoped></style>

import { DateTime, Interval } from 'luxon'

function diffToday(d2, units) {
  return diff(new Date(), d2, units)
}

function diff(d1, d2, units) {
  if (!units) units = ['weeks', 'days']

  let dt1 = DateTime.fromJSDate(d1 < d2 ? d1 : d2)
  let dt2 = DateTime.fromJSDate(d1 < d2 ? d2 : d1)

  let di = Interval.fromDateTimes(dt1, dt2).toDuration(units).toObject()
  for (const key in di) {
    if (Object.hasOwnProperty.call(di, key)) {
      let v = di[key]
      di[key] = Math.round(v)
    }
  }
  return di
}

export { diffToday, diff }

<template>
  <div>
    <!-- serch text -->
    <div>
      <v-text-field
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Buscar"
        outlined
        v-model="searchText"
        class="mb-1"
        hide-details
      ></v-text-field>
    </div>

    <!-- list-->
    <div>
      <div v-for="item in filteredList" :key="item.id">
        <div class="item" @click="editMe(item)">
          <b>{{ item.title }}</b> | <span>{{ item.nombre }}</span>
          <br />
          Total factura:
          <b
            ><span :style="{ color: item.type == 'gasto' ? 'red' : 'green' }">{{ total_factura(item) }} EUR</span></b
          >
          <br />
          {{ item.date }}
        </div>
      </div>
    </div>

    <!-- buffer space -->
    <div style="height: 60px"></div>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- edit -->
    <v-dialog v-if="edit" v-model="edit" scrollable max-width="600" :fullscreen="isMobile">
      <v-card :loading="loading">
        <v-card-title></v-card-title>
        <v-card-text>
          <AutonomoEdit :id="editItem.id" @change="onChange($event)" />
        </v-card-text>
        <v-card-actions style="box-shadow: 0px 0px #ccc, 0px 0px 10px #ccc; border-top: 1px solid #ccc">
          <v-btn @click="edit = false" depressed color="primary">Cancelar</v-btn>
          <v-btn @click="remove(editItem)" depressed color="error">Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save" depressed color="success">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/assets/api'
import utils from '@/assets/utils'
import AutonomoEdit from './AutonomoEdit.vue'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
    AutonomoEdit,
  },
  data() {
    return {
      isMobile: false,
      searchText: '',
      loading: false,
      items: [],
      editItem: {},
      edit: false,
    }
  },
  computed: {
    filteredList() {
      let items = [...this.items]
      let output = []
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const text = [
          item.title,
          item.type,
          item.nombre,
          item.nif,
          item.date,
          this.total_factura(item),
          item.comentarios,
          item.descripcion,
        ].join(' ')
        if (utils.search.searchMatch(this.searchText, text)) {
          output.push(item)
        }
      }
      return output
    },
  },
  methods: {
    total_factura(item) {
      return (+item.base_imponible * (1 + (+item.tipo_iva - +item.tipo_retencion) / 100)).toFixed(2)
    },
    async remove(item) {
      const id = item.id
      let sure = confirm(`Seguro que quieres borrar este ${item.type}?`)
      if (!sure) return
      let i = this.items.findIndex((x) => x.id == id)
      this.loading = true
      let x = await api.apiFetch('/autonomo/delete/' + id)
      this.loading = false
      if (x.ok) {
        console.log('removed')
        this.items.splice(i, 1)
      } else {
        alert('error')
      }
    },
    onChange(item) {
      this.editItem = { ...item }
    },
    editMe(item) {
      this.editItem = item
      this.edit = true
    },
    async save() {
      this.loading = true
      if (!this.editItem) this.editItem = {}
      if (!this.editItem.id) {
        this.editItem.id = uuidv4()
      }
      let x = await api.apiFetch('/autonomo/put', {
        method: 'POST',
        body: JSON.stringify(this.editItem),
      })
      if (x.ok) {
        console.log('saved')
      } else {
        alert('error')
      }
      let i = this.items.findIndex((x) => x.id == this.editItem.id)
      if (i == -1) {
        this.items.push({ ...this.editItem })
      } else {
        this.items[i] = { ...this.editItem }
      }

      this.items = [...this.items] //JSON.parse(JSON.stringify(this.items))

      this.editItem = {}
      this.loading = false
      this.edit = false
    },
    async addNewItem() {
      this.editItem = {} //TODO: probably worth fine tuning so we can continue editing same value after pressed cancel for new items.
      this.edit = true

      console.log('not ready yet')
    },
    async fetchAll() {
      this.loading = true
      let x = await api.apiFetch('/autonomo/getall')
      if (x.ok) {
        this.items = await x.json()
      }
      this.loading = false
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768
    this.fetchAll()
  },
}
</script>

<style scoped>
.item {
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>

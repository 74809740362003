<template>
  <div>
    <div class="wrapper">
      <div class="item">
        <div class="title">{{ item.title }}</div>
        <div v-for="intention in item.intentions" :key="intention">
          <div class="itention">
            {{ intention }}
          </div>
        </div>
      </div>
    </div>

    <div style="position: fixed; bottom: 60px; right: 20px; display: flex" @click="randomize()">
      <v-btn color="error" class="mr-3" outlined>
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-btn color="success" outlined>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['username']),
  },
  data() {
    return {
      item: { title: '', intentions: [] },
      items: [
        { id: '1', title: 'Rocío', intentions: ['Trabajo'], tags: ['family', 'important'] },
        { id: '2', title: 'Ángela Madre', intentions: ['Situación actual', 'Salud'], tags: ['family', 'important'] },
        { id: '3', title: 'Papi', intentions: ['Trabajo'], tags: ['family', 'important'] },
        { id: '4', title: 'Raulpe y Linda', intentions: ['Fe', 'familia'], tags: ['friends'] },
      ],
    }
  },
  methods: {
    randomize() {
      const index = Math.floor(Math.random() * this.items.length)
      this.item = this.items[index]
    },
  },
  mounted() {
    if (this.username != 'kaixo') {
      this.items = []
    }
    this.randomize()
  },
}
</script>

<style scoped>
.item {
  padding: 30px;
}

.wrapper {
  height: 100%;
  padding-top: 40%;
}
</style>

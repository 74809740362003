<template>
  <div>
    <h2>Pregnancy</h2>

    <FormDatePickerVue v-model="dueDateText" outlined label="Due date" style="margin-top: 10px" />

    <!-- counter -->
    <div class="wrapper">
      <div class="box" v-for="(value, k) of diffToday(startDate, ['weeks', 'days'])" :key="k">
        <div class="number">{{ value }}</div>
        <div class="label">{{ k }}</div>
      </div>
    </div>

    <!-- done -->
    <div style="margin-top: 20px">
      <b>Llevamos...</b>
      <div style="font-weight: bold; padding-left: 8px">
        {{ diffToday(startDate).weeks }} weeks, {{ diffToday(startDate).days }} days
      </div>
      <div style="font-weight: bold; padding-left: 8px">
        {{ diffToday(startDate, ['months', 'days']).months }} months,
        {{ diffToday(startDate, ['months', 'days']).days }} days
      </div>
    </div>

    <!-- pending -->
    <div style="margin-top: 20px">
      <b>Falta...</b>
      <div style="font-weight: bold; padding-left: 8px">
        {{ diffToday(dueDate).weeks }} weeks, {{ diffToday(dueDate).days }} days
      </div>
      <div style="font-weight: bold; padding-left: 8px">
        {{ diffToday(dueDate, ['months', 'days']).months }} months,
        {{ diffToday(dueDate, ['months', 'days']).days }} days
      </div>
    </div>

    <!-- inputs -->
    <div style="margin-top: 30px">
      <b>Inputs</b>
      <ul>
        <li>Due Date: {{ dueDateText }}</li>
        <li>Start Date: {{ formatDate(startDate) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import FormDatePickerVue from '@/components/FormDatePicker.vue'

export default {
  components: {
    FormDatePickerVue,
  },
  data() {
    return {
      dueDateText: '2024-12-30', //DateTime.now().toFormat('yyyy-MM-dd'),
      //dueDateText: '2023-06-22', //DateTime.now().toFormat('yyyy-MM-dd'),
      today: DateTime.now().toFormat('yyyy-MM-dd'),
    }
  },
  computed: {
    dueDate() {
      return new Date(this.dueDateText)
    },
    startDate() {
      const start = new Date(this.dueDate.getTime() - 280 * 24 * 60 * 60 * 1000) // Assuming pregnancy lasts 280 days
      return start
    },
  },
  methods: {
    jsonToString(object) {
      let auxx = []
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const value = object[key]
          auxx.push(value + ' ' + key)
        }
      }
      return auxx.join(', ')
    },
    diffToday(d2, units) {
      return this.diff(new Date(this.formatDate(new Date())), d2, units)
    },
    diff(d1, d2, units) {
      if (!units) units = ['weeks', 'days']

      let dt1 = DateTime.fromJSDate(d1 < d2 ? d1 : d2)
      let dt2 = DateTime.fromJSDate(d1 < d2 ? d2 : d1)

      let di = Interval.fromDateTimes(dt1, dt2).toDuration(units).toObject()
      for (const key in di) {
        if (Object.hasOwnProperty.call(di, key)) {
          let v = di[key]
          di[key] = Math.round(v)
        }
      }
      return di
    },
    formatDate(date, format) {
      if (!format) format = 'yyyy-MM-dd'
      return DateTime.fromJSDate(date).toFormat(format)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;

  justify-content: space-evenly;

  max-width: 200px;
  margin: auto;
}
.box {
  flex: 1 1 0px;
  width: 0px;

  background-color: lightblue;
  color: darkblue;

  font-size: 2rem;
  margin: auto;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
}

.box .number {
  padding: 10px 20px 0px 20px;
}
.box .label {
  font-size: 0.75rem;
  margin-bottom: 5px;
}
</style>

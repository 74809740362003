<template>
  <div class="app">
    <div class="container2">
      <h1 class="header2">Tasks</h1>
      <div class="tasks-grid">
        <TaskCard v-for="task in tasks" :key="task.task_id" :task="task" :isCritical="isTaskCritical(task)" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'
import TaskCard from './TaskCard.vue'

export default {
  components: {
    TaskCard,
  },
  data() {
    return {
      loading: false,
      tasks: [
        // ... Your tasks array
      ],
      criticalPath: [],
    }
  },
  methods: {
    isTaskCritical(task) {
      return this.criticalPath.includes(task)
    },
    calculateCriticalPath(tasks) {
      // Step 1: Calculate the earliest start (ES) and earliest finish (EF) times for each task
      const calculateEarliestTimes = (task) => {
        if (task.ES === undefined) {
          task.ES =
            task.predecessors.length === 0
              ? 0
              : Math.max(
                  ...task.predecessors.map((p) => {
                    calculateEarliestTimes(p)
                    return p.EF
                  })
                )
          task.EF = task.ES + task.duration
        }
      }

      tasks.forEach((task) => calculateEarliestTimes(task))

      // Step 2: Calculate the latest start (LS) and latest finish (LF) times for each task
      const calculateLatestTimes = (task) => {
        if (task.LF === undefined) {
          task.LF =
            task.successors.length === 0
              ? task.EF
              : Math.min(
                  ...task.successors.map((s) => {
                    calculateLatestTimes(s)
                    return s.LS
                  })
                )
          task.LS = task.LF - task.duration
        }
      }

      tasks.forEach((task) => calculateLatestTimes(task))

      // Step 3: Identify the critical path
      const criticalPath = tasks.filter((task) => task.ES === task.LS)

      return criticalPath
    },
    async fetchData() {
      this.loading = true
      let x = await api.apiFetch('/todos/getall')
      if (x.ok) {
        this.tasks = await x.json()
      }
      this.loading = false
    },
  },
  async mounted() {
    await this.fetchData()
    // Calculate the critical path using the function from the previous answer
    this.criticalPath = this.calculateCriticalPath(this.tasks)
  },
}
</script>

<style>
.app {
  background-color: #f1f1f1;
  min-height: 100vh;
}

.container2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.tasks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
}
</style>

<template>
  <div>
    <div>
      <router-link to="/code/sublime-autocompletion"> Sublime autocompletion</router-link>
    </div>
    <div>
      <router-link to="/code/javadoc"> Javadoc typeof helper </router-link>
    </div>
    <div>
      <router-link to="/code/typedef2interface">Typedef to Interface (ts)</router-link>
    </div>
    <div>
      <router-link to="/code/icons"> Icons Generator </router-link>
    </div>
    <div>
      <router-link to="/code/shamir">Shamir Secret Share</router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <h1>Política de Privacidad</h1>
    <div>
      <p>Pasaparaguas se compromete a respetar su privacidad y la confidencialidad de su información personal.</p>
      <p>
        Pasaparaguas incluye este sitio web (https://pasaparaguas.pasalo.es) y la extensión de Chrome Pasaparaguas
        (también conocida como simplemente "paraguas"). Cuando hablamos de Pasaparaguas en esta página, nos referimos a
        ambos a menos que se especifique lo contrario.
      </p>
      <h2>1. ¿Qué tipo de información recaba y usa Pasaparaguas?</h2>
      <p>
        Pasaparaguas puede recolectar y usar información sobre usted, que incluye, de manera enunciativa pero no
        taxativa, información de los siguientes tipos:
      </p>
      <ul>
        <li>
          Información identificatoria (por ejemplo, nombre, nombre de usuario, dirección de correo electrónico)<br />
        </li>
        <li>Información de identificación electrónica (por ejemplo, direcciones IP, cookies)</li>
        <li>
          Contenido de las páginas que usted visita con la extensión de Chrome, a las que la extensión tiene acceso, con
          el único y exclusivo propósito de hacer funcionar las herramientas de Pasaparaguas.
        </li>
      </ul>

      <h2>2. Cookies</h2>
      <p>
        Al usar lo sitio web de Pasaparaguas, usted da su consentimiento para el uso de cookies. Puede optar por
        rechazar las cookies cuando lo desee, ya sea cambiando la configuración del explorador (si su explorador lo
        permite), dejando de usar las herramientas de Pasaparaguas o usando las funciones de cancelación
        correspondientes.
      </p>
      <h2>2.1 Cookies de terceros</h2>
      <p>
        Además de las cookies de Pasaparaguas, los afiliados y socios de servicios publicitarios y análisis de
        Pasaparaguas pueden configurar cookies y acceder a cookies de su equipo, cuando usted esté usando Pasaparaguas.
      </p>
      <h3>2.1.1 Análisis</h3>
      <p>
        Pasaparaguas usa Google Analytics para análisis a fin de recopilar información anónima de estadísticas y de
        análisis sobre cómo los usuarios usan Pasaparaguas.
      </p>
      <h3>2.1.2 Publicidad</h3>
      <p>
        En Pasaparaguas usamos anuncios de Google. Google, como proveedor de publicidad, utiliza cookies para publicar
        anuncios en este sitio. El uso de cookies de DART de Google permite servir anuncios a los visitantes que se
        basan en sus visitas a las herramientas de Pasaparaguas, así como otros sitios en la internet. Para optar por
        las cookies de DART se puede visitar el anuncio de Google y la política de privacidad de la red de contenido en
        la URL de seguimiento http://www.google.com/privacy_ads.html siguiente de los usuarios a través de los
        mecanismos cookie de DART están sujetos a las políticas de privacidad de Google.
      </p>
      <h2>3. Afiliados de terceros</h2>
      <p>
        Participamos en la comercialización de afiliados y podemos permitir que los enlaces de afiliados se codifiquen
        en algunas de nuestras páginas. Esto significa que podemos ganar una comisión al hacer clic o al realizar
        compras a través de enlaces de afiliados.
      </p>
      <h3>3.1 Condiciones de afiliado de Amazon</h3>
      <p>
        Pasaparaguas es un participante en el Programa de Afiliados de Amazon Services LLC, un programa de publicidad de
        afiliados diseñado para proporcionar un medio para que los propietarios de sitios web ganen comisiones
        publicitarias publicitando y enlazando a amazon.com y cualquier otro sitio web que pueda estar afiliado con
        Amazon Service LLC Associates Program.
      </p>
      <h2>4. ¿Se puede modificar esta política de privacidad?</h2>
      <p>
        En algunos casos, Pasaparaguas actualizará esta Política de Privacidad para reflejar los cambios oportunos.
        Cuando publiquemos cambios en la Política de Privacidad pasaparaguas.pasalo.es/privacidad, actualizaremos la
        fecha de “última revisión” al pie de la Política de Privacidad.
      </p>
      <p>Última revisión: Diciembre de 2022</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 40px;
}
</style>

<template>
  <div>
    <v-dialog v-model="showFoodPicker" scrollable max-width="500">
      <FoodPicker
        v-if="showFoodPicker"
        @cancel="showFoodPicker = false"
        :items="food"
        :value="currentValue"
        @change="onFoodChanged"
      />
    </v-dialog>

    <v-list-item :style="{ color: loading ? 'grey' : '' }">
      <v-list-item-content v-if="value && value.id" @click="showFoodPicker = true">
        <v-list-item-title>{{ value.title }}</v-list-item-title>
        <v-list-item-subtitle
          >{{ value.meal.join('/') }} | {{ value.content }} | {{ value.ana ? 'Ana' : 'No Ana' }}</v-list-item-subtitle
        >
      </v-list-item-content>

      <v-list-item-content v-else @click="showFoodPicker = true">
        <v-list-item-title style="color: grey">&nbsp;</v-list-item-title>
        <v-list-item-subtitle>Click to pick</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <div style="display: flex">
          <v-btn icon @click.prevent="randomFood">
            <v-icon color="orange">mdi-shimmer</v-icon>
          </v-btn>
          <div style="width: 10px"></div>
          <v-btn icon @click.prevent="removeFood">
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import FoodPicker from './FoodPicker.vue'
export default {
  components: { FoodPicker },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    meal: {
      type: String,
      default: '',
    },
    order: {
      type: String,
      default: '',
    },
    food: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFoodPicker: false,
    }
  },
  computed: {
    currentValue() {
      let x = this.value && this.value.id ? this.value.id : ''
      return x
    },
  },
  methods: {
    removeFood() {
      this.onFoodChanged('')
    },
    randomFood() {
      let candidates = [
        ...this.food.filter(
          (x) => (x.meal.includes(this.meal) || !x.meal || !x.meal.length) && (x.order == this.order || !x.order)
        ),
      ] //candidates are the items that (a) matches meal (comida/cena) and the order (primero/segundo), or (b) lack those fields (otherwise those never appear)
      console.log('candidates', candidates)
      let randomIndex = Math.floor(Math.random() * candidates.length)
      console.log('random index', randomIndex)
      let food = candidates[randomIndex]
      //alert('Random result is... ' + food.title)
      this.onFoodChanged(food.id)
    },
    onFoodChanged(id) {
      this.$emit('change', id)
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="autocomplete">
    <textarea
      ref="textarea"
      v-model="inputText"
      @keydown="onKeyDown"
      @keyup="onKeyUp"
      @input="updateCursorPosition"
      class="autocomplete-input"
    ></textarea>
    <div ref="hiddenDiv" class="hidden-div">
      <span ref="cursorSpan"></span>
    </div>

    {{ cursorPosition }} | {{ showSuggestions }} |{{ hashtags }}
    <ul
      v-if="showSuggestions"
      class="autocomplete-suggestions"
      :style="{ top: cursorPosition.y + 'px', left: cursorPosition.x + 'px' }"
    >
      <li
        v-for="(suggestion, index) in filteredSuggestions"
        :key="index"
        :class="{ 'active-suggestion': index === activeSuggestionIndex }"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    vModel: String,
    hashtags: Array,
  },
  data() {
    return {
      inputText: this.vModel,
      showSuggestions: false,
      activeSuggestionIndex: 0,
      filteredSuggestions: [],
      cursorPosition: { x: 0, y: 0 },
    }
  },
  watch: {
    inputText(newVal) {
      this.$emit('input', newVal)
      this.updateSuggestions()
    },
  },
  methods: {
    updateSuggestions() {
      const hashtagRegex = /#(\w+)$/g
      const match = this.inputText.match(hashtagRegex)
      if (match && match[0]) {
        const searchTerm = match[0].substring(1)
        console.log('searchTerm', searchTerm)
        this.filteredSuggestions = this.hashtags.filter((hashtag) =>
          hashtag.toLowerCase().startsWith(searchTerm.toLowerCase())
        )
        this.showSuggestions = this.filteredSuggestions.length > 0
      } else {
        this.showSuggestions = false
      }
    },
    onKeyDown(event) {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault()
          if (this.activeSuggestionIndex > 0) {
            this.activeSuggestionIndex--
          }
          break
        case 'ArrowDown':
          event.preventDefault()
          if (this.activeSuggestionIndex < this.filteredSuggestions.length - 1) {
            this.activeSuggestionIndex++
          }
          break
        case 'Enter':
          event.preventDefault()
          if (this.showSuggestions) {
            this.selectSuggestion(this.filteredSuggestions[this.activeSuggestionIndex])
          }
          break
        case 'Tab':
          event.preventDefault()
          if (this.showSuggestions) {
            this.selectSuggestion(this.filteredSuggestions[this.activeSuggestionIndex])
          }
          break
      }
    },
    onKeyUp(event) {
      if (!['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes(event.key)) {
        this.updateSuggestions()
      }
    },
    selectSuggestion(suggestion) {
      const hashtagRegex = /#(\w+)$/g
      this.inputText = this.inputText.replace(hashtagRegex, suggestion)
      this.showSuggestions = false
      this.$refs.textarea.focus()
    },
    updateCursorPosition() {
      const { x, y } = this.getCursorPosition(this.$refs.textarea) //this.calculateCursorPosition()
      this.cursorPosition = { x, y }
    },
    calculateCursorPosition() {
      const textArea = this.$refs.textarea
      const range = document.createRange()
      const selection = window.getSelection()

      if (textArea.childNodes.length > 0) {
        const textNode = textArea.childNodes[0]
        range.setStart(textNode, 0)
        range.setEnd(textNode, textArea.selectionEnd)
        selection.removeAllRanges()
        selection.addRange(range)

        const { x, y, height } = range.getBoundingClientRect()

        return { x, y: y + height }
      } else {
        return { x: 0, y: 0 }
      }
    },

    getCursorPosition(textArea) {
      if (!textArea.firstChild) {
        textArea.appendChild(document.createTextNode(''))
      }
      const { selectionStart } = textArea
      const range = document.createRange()
      const span = document.createElement('span')
      const rect = textArea.getBoundingClientRect()

      span.innerHTML = '&zwj;'
      range.setStart(textArea.firstChild, selectionStart)
      range.setEnd(textArea.firstChild, selectionStart)
      range.insertNode(span)

      const { left, top } = span.getBoundingClientRect()
      const { scrollTop, scrollLeft } = textArea
      span.remove()

      const x = left - rect.left - scrollLeft + textArea.offsetLeft
      const y = top - rect.top - scrollTop + textArea.offsetTop

      return { x, y }
    },
  },
}
</script>

<style scoped>
.autocomplete {
  position: relative;
}

.autocomplete-input {
  width: 50%;
  box-sizing: border-box;
}

.autocomplete-suggestions {
  position: absolute;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}

.autocomplete-suggestions li {
  padding: 8px 16px;
  cursor: pointer;
}

.autocomplete-suggestions li:hover,
.active-suggestion {
  background-color: #f0f0f0;
}
</style>

<template>
  <div>
    <NotificationsViewVue />
  </div>
</template>

<script>
import NotificationsViewVue from './NotificationsView.vue'

export default {
  components: {
    NotificationsViewVue,
  },
}
</script>

<style lang="scss" scoped></style>

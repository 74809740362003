<template>
  <div class="editor-container">
    <div class="editor-toolbar">
      <button @click="toggleBold" :class="{ active: isBold }">Bold</button>
      <button @click="toggleItalic" :class="{ active: isItalic }">Italic</button>
      <button @click="toggleHeader1" :class="{ active: isHeader1 }">Header 1</button>
      <button @click="toggleHeader2" :class="{ active: isHeader2 }">Header 2</button>
    </div>
    <div class="editor-body" contenteditable="true" @input="onInput" ref="editorBody" :key="componentKey">
      {{ markdown }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarkdownEditor',
  props: {
    value: {
      type: String,
      required: true,
      validator: (value) => typeof value === 'string',
    },
  },
  data() {
    return {
      markdown: this.value,
      componentKey: 0,
      isBold: false,
      isItalic: false,
      isHeader1: false,
      isHeader2: false,
    }
  },
  methods: {
    onInput(event) {
      this.markdown = event.target.textContent
      this.$emit('input', this.markdown)
      this.updateFormattingState()
    },
    toggleBold() {
      this.toggleFormatting('b')
      this.updateFormattingState()
    },
    toggleItalic() {
      this.toggleFormatting('i')
      this.updateFormattingState()
    },
    toggleHeader1() {
      this.toggleFormatting('h1')
      this.updateFormattingState()
    },
    toggleHeader2() {
      this.toggleFormatting('h2')
      this.updateFormattingState()
    },
    toggleFormatting(format) {
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const formattedNode = document.createElement(format)
        formattedNode.appendChild(range.extractContents())
        range.insertNode(formattedNode)
        selection.removeAllRanges()
        selection.addRange(range)
        this.onInput()
      }
    },
    updateFormattingState() {
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const startContainer = range.startContainer
        this.isBold = startContainer.parentElement.tagName === 'B'
        this.isItalic = startContainer.parentElement.tagName === 'I'
        this.isHeader1 = startContainer.parentElement.tagName === 'H1'
        this.isHeader2 = startContainer.parentElement.tagName === 'H2'
      } else {
        this.isBold = false
        this.isItalic = false
        this.isHeader1 = false
        this.isHeader2 = false
      }
    },
  },
  watch: {
    value(newValue) {
      this.markdown = newValue
      this.componentKey++
    },
  },
}
</script>

<style scoped>
.editor-container {
  border: 1px solid #ccc;
  padding: 1rem;
}

.editor-toolbar {
  display: flex;
  margin-bottom: 0.5rem;
}

.editor-toolbar button {
  margin-right: 0.5rem;
}

.editor-toolbar button.active {
  background-color: #ccc;
}

.editor-body {
  min-height: 6rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  overflow-y: auto;
}
</style>

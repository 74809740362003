var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"max-width":"500"}},[_c('v-card-title',[_vm._v("Edit event "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-text-field',{staticClass:"mb-2",attrs:{"dense":"","hide-details":"","outlined":"","clearable":"","label":"Title"},model:{value:(_vm.draft.summary),callback:function ($$v) {_vm.$set(_vm.draft, "summary", $$v)},expression:"draft.summary"}}),_c('v-textarea',{staticClass:"mb-2",attrs:{"dense":"","rows":"1","auto-grow":"","hide-details":"","outlined":"","clearable":"","label":"Location"},model:{value:(_vm.draft.location),callback:function ($$v) {_vm.$set(_vm.draft, "location", $$v)},expression:"draft.location"}}),_c('div',{staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Tag: "),_c('ChipDropdown',{attrs:{"items":[
          '👨‍⚕️ pediatra',
          '🏫 colegio',
          '👶 guarde',
          '🤰 gine',
          '🎁 anniversary',
          '🏥 hospital',
          '🍺 social',
          '✏️ papeleo' ]},model:{value:(_vm.draft.alex.tag),callback:function ($$v) {_vm.$set(_vm.draft.alex, "tag", $$v)},expression:"draft.alex.tag"}})],1),_c('v-textarea',{staticClass:"mb-1",attrs:{"dense":"","rows":"2","auto-grow":"","hide-details":"","outlined":"","clearable":"","label":"Description"},model:{value:(_vm.draft.alex.description),callback:function ($$v) {_vm.$set(_vm.draft.alex, "description", $$v)},expression:"draft.alex.description"}}),_c('div',[_c('v-checkbox',{attrs:{"label":"All day"},model:{value:(_vm.allday),callback:function ($$v) {_vm.allday=$$v},expression:"allday"}},[_vm._v("All day")]),_c('DateTimeEdit',{attrs:{"allday":_vm.allday,"label":"Start Date"},model:{value:(_vm.draft.start),callback:function ($$v) {_vm.$set(_vm.draft, "start", $$v)},expression:"draft.start"}}),_c('DateTimeEdit',{attrs:{"allday":_vm.allday,"label":"End Date"},model:{value:(_vm.draft.end),callback:function ($$v) {_vm.$set(_vm.draft, "end", $$v)},expression:"draft.end"}})],1),(_vm.draft.creator && _vm.draft.creator.email)?_c('p',{staticClass:"subtext"},[_vm._v("Creator: "+_vm._s(_vm.draft.creator.email))]):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.save(_vm.draft)}}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
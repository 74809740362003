<!-- TodoForm.vue -->
<template>
  <v-card :loading="loading">
    <v-card-title v-if="false"> data.id: {{ data.id }} </v-card-title>

    <v-card-text>
      <v-form @submit.prevent="submitForm">
        <v-textarea
          ref="datatitle"
          v-model="data.title"
          required
          auto-grow
          placeholder="title"
          rows="1"
          style="font-size: 14pt; font-weight: 800"
        ></v-textarea>
        <v-textarea
          outlined
          auto-grow
          rows="1"
          label="Description"
          v-model="data.description"
          hide-details
        ></v-textarea>

        <v-checkbox
          v-model="data.completed"
          :label="data.completed ? 'Completed on ' + data.completedAt.substring(0, 10) : 'Completed'"
        ></v-checkbox>
        <v-row>
          <v-col>
            <v-select dense outlined label="Priority" v-model="data.priority" :items="priorityOptions"></v-select>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              type="number"
              step="0.5"
              v-model="data.effort_hours"
              label="Effort (hours)"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- dates -->
        <div>
          <!-- single calendar -->

          <div>
            <v-menu
              v-model="menuDates"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="niceDays"
                  label="key dates"
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  append-icon="mdi-close"
                  @click:append="resetDates"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker multiple no-title scrollable v-model="dates"></v-date-picker>
            </v-menu>
          </div>

          <!-- weekday shortcut -->
          <div style="text-align: center; margin: 15px 0px">
            <span
              class="modern-link"
              style="margin-bottom: 5px; margin-left: 8px; margin-right: 8px; font-size: 12pt"
              v-for="(x, index) in datesArray"
              :key="x"
              @click="datesShortcut(index)"
              >{{ x }}</span
            >
          </div>

          <!-- rank -->
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                type="number"
                step="0.5"
                v-model="data.rank"
                label="Rank"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <p style="font-size: 90%">Created At: {{ data.createdAt }}</p>
            </v-col>
          </v-row>

          <!-- item per each -->
          <div v-if="false">
            <div style="display: flex">
              <form-date-picker outlined v-model="data.eta" label="Hard ETA" />
              <div>
                {{ format(data.eta, 'cccc') }}
              </div>
            </div>
            <form-date-picker outlined v-model="data.internal_eta" label="Internal ETA" />
            <form-date-picker outlined v-model="data.focus_date" label="Focus date" />
          </div>
        </div>

        <div v-if="false">
          <TextareaAutocompleteVue v-model="data.description" :hashtags="['hellow', '#hey', '#there']" />
        </div>

        <!-- tags //TODO: remove this part -->
        <div v-if="false">
          <div>
            <v-chip
              v-for="tag in knownTags"
              :key="tag"
              @click="toggleTag(tag)"
              class="ml-1 mt-1"
              small
              :outlined="!data.tags || !data.tags.includes(tag)"
              >{{ tag }}</v-chip
            >
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions style="border-top: 1px solid #ccc">
      <v-btn text color="primary" @click="cancel">cancel</v-btn>
      <v-btn text color="error" @click="deleteItem">delete</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="success" @click="save">save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

import api from '@/assets/api'
import FormDatePicker from '@/components/FormDatePicker.vue'
import TextareaAutocompleteVue from '@/components/TextareaAutocomplete.vue'

export default {
  components: { FormDatePicker, TextareaAutocompleteVue },
  props: {
    id: {
      type: String,
      default: '',
    },
    isnew: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dates(newValue) {
      let auxx = [...newValue]
      if (auxx.length > 3) {
        let newDate = newValue[3]
        const minDate = auxx.reduce((a, b) => (a <= b ? a : b))
        const maxDate = auxx.reduce((a, b) => (a >= b ? a : b))

        let i = newDate >= maxDate ? 2 : newDate <= minDate ? 0 : 1
        auxx[i] = newDate
        auxx.splice(3, 1)
        this.dates = [...auxx]
      } else {
        let aux2 = [...newValue]
        let sorted = [...newValue].sort()
        if (aux2.join() != sorted.join()) this.dates = [...sorted]

        this.data.eta = sorted.length > 0 ? sorted[sorted.length - 1] : null
        this.data.internal_eta = sorted.length > 1 ? sorted[sorted.length - 2] : null
        this.data.focus_date = sorted.length > 0 ? sorted[0] : null
      }
    },
    id() {
      this.fetchData()
    } /*
    data: {
      deep: true,
      handler(n, o) {
        if (n.eta && n.eta != o.eta && !n.internal_eta) {
          n.internal_eta = DateTime.fromJSDate(new Date(n.eta)).minus({ days: 3 }).toFormat('yyyy-MM-dd')
        }
      },
    },*/,
  },
  computed: {
    niceDays() {
      let a = this.dates ? [...this.dates] : []
      return a.map((x) => DateTime.fromJSDate(new Date(x)).toFormat('ccc dd')).join(', ')
    },
    datesArray() {
      let output = []
      for (let i = 0; i < 7; i++) {
        let d = DateTime.now().plus({ days: i }).toFormat('DDDD').toUpperCase().substring(0, 3)

        if (d != 'SAT' && d != 'SUN') {
          output.push(d)
        }
      }
      return output
    },
  },
  data() {
    return {
      menuDates: false,
      dates: [],
      loading: false,
      knownTags: ['committed', 'andres', 'ps', 'smart', 'op1', 'other'],
      data: {
        id: '',
        title: '',
        description: '',
        eta: null,
        hideUntil: '2020-01-01',
        priority: 1,
      },
      priorityOptions: [
        { text: 'High', value: 0 },
        { text: 'Medium', value: 1 },
        { text: 'Low', value: 2 },
      ],
    }
  },
  methods: {
    toggleTag(tag) {
      console.log('toggleTag', tag)
      let auxx = this.data.tags ? [...this.data.tags] : []
      let i = auxx.findIndex((t) => t == tag)
      if (i == -1) {
        auxx.push(tag)
      } else {
        auxx.splice(i, 1)
      }
      this.data.tags = [...auxx]
      this.data = { ...this.data }
    },

    datesShortcut(i) {
      const today = new Date()
      //let n = i //+ 1 //removing the +1 as it seems it messes up (not sure why I added it tbh)
      //const nextOcc = new Date(today.getFullYear(), today.getMonth(), today.getDate() + ((n - today.getDay() + 7) % 7))

      const nextOcc = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i)
      this.dates.push(this.format(nextOcc, 'yyyy-MM-dd'))
    },
    resetDates() {
      this.dates = []
    },
    format(d, f) {
      return DateTime.fromJSDate(new Date(d)).toFormat(f)
    },
    async fetchData() {
      if (this.isnew) this.$refs.datatitle.focus()
      this.data = {}
      if (!this.id) {
        console.log('error, expeced id')
        return
      }
      this.loading = true
      let x = await api.apiFetch('/todos/get/' + this.id)

      if (x.ok) {
        let j = await x.json()
        if (j && j.id) {
          //existing item
          this.data = j

          this.dates = []
          if (this.data.focus_date) this.dates.push(this.data.focus_date)
          if (this.data.internal_eta) this.dates.push(this.data.internal_eta)
          if (this.data.eta) this.dates.push(this.data.eta)
          //this.dates = [this.data.focus_date, this.data.internal_eta, this.data.eta]
        } else {
          //new item

          this.data = {}
          this.data.id = this.id
          this.data.tags = []
          this.data.eta = DateTime.now().plus({ days: 7 }).toFormat('yyyy-MM-dd')
          this.data.internal_eta = DateTime.now().plus({ days: 5 }).toFormat('yyyy-MM-dd')
          this.data.focus_date = DateTime.now().plus({ days: 3 }).toFormat('yyyy-MM-dd')
          this.dates = [this.data.focus_date, this.data.internal_eta, this.data.eta]
        }
      } else {
        this.data = {}
        this.data.id = this.id
        this.data.title = ''
        this.data.tags = []
        this.dates = []
      }

      this.loading = false
    },
    cancel() {
      this.$emit('canceled')
    },
    async save() {
      let x = await api.apiFetch('/todos/put', {
        method: 'POST',
        body: JSON.stringify(this.data),
      })
      if (x.ok) {
        this.$emit('saved')
      } else {
        alert('Ouch. Not saved')
      }
    },
    async deleteItem() {
      let sure = confirm('You sure')
      if (!sure) return
      let x = await api.apiFetch('/todos/delete/' + this.id, {
        method: 'POST',
      })
      if (x.ok) {
        this.$emit('saved') //is the same upstream kind of
      }
    },
  },
  async mounted() {
    await this.fetchData()
  },
}
</script>

<template>
  <div>
    <!-- buttons and menu -->
    <div v-if="loading_lists">
      <b>Loading lists....</b>
    </div>
    <div v-else>
      <h1 style="padding-bottom: 0px">{{ selectedList.title }}</h1>
      <span style="font-size: 80%; color: rgb(100, 100, 100); padding-bottom: 8px"
        >Actualizada el {{ new Date(selectedList.updatedAt).toLocaleString() }}
        {{ selectedList.updatedBy ? ' por ' + selectedList.updatedBy : '' }} |
        {{ selectedList.asins.length }} asins</span
      >

      <!--
    <div
      v-if="false"
      style="position: fixed; bottom: 10px; right: 20px; z-index: 9999999"
    >
      <v-btn color="primary" fab dark class="mb-10" @click="drawer = !drawer">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </div>
     -->

      <!-- bottom menu with info about list -->
      <div
        v-if="!drawer"
        style="
          position: fixed;
          bottom: 0px;
          left: 0px;
          right: 0px;
          z-index: 99999;
          background-color: rgb(236, 236, 236);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px;
        "
      >
        <div style="padding-left: 5px">
          <b> {{ selectedList.title }} ({{ selectedList.asins.length }} asins) </b>
          <br />
          <span style="font-size: 90%">
            Lista actualizada el
            {{ new Date(selectedList.updatedAt).toLocaleDateString() }}
          </span>
        </div>
        <div>
          <v-btn color="primary" icon @click="editMode = !editMode">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="primary" icon @click="drawer = !drawer">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </div>
      </div>

      <div style="display: flex; margin-top: 5px; align-items: center">
        <v-btn v-if="!editMode" @click="drawer = !drawer" depressed color="primary">{{
          drawer ? 'Ocultar listas' : 'Ver listas'
        }}</v-btn>
        <v-btn v-if="!editMode" @click="editMode = !editMode" depressed class="ml-2" color="primary">Editar</v-btn>
        <div>
          <div v-if="editMode" style="margin-bottom: 8px">
            <v-btn color="primary" depressed class="ml-4" @click="editMode = false">Cancelar</v-btn>
            <v-btn color="success" depressed class="ml-4" @click="saveAsinList()">Guardar</v-btn>
          </div>
        </div>

        <!-- compact mode -->
        <div>
          <v-switch v-model="compactLayout" :label="'compacto'"> </v-switch>
        </div>
      </div>

      <!-- lists -->

      <div>
        <v-navigation-drawer app v-model="drawer">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6"> Listas de Asins </v-list-item-title>
              <v-list-item-subtitle> Elije una lista para ver los asins </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-item-group :value="tab">
              <v-list-item v-for="item in lists" :key="item.id" link @click="selected_id = item.id">
                <v-list-item-icon v-if="item.icon">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> {{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.asins.length }} asins | Hace
                    {{ listUpdated(item.updatedAt).s }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-btn block depressed color="success" @click="newList()">Nueva Lista</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- let's try out an add here -->
          <AdSenseFeed />
          <!-- some space to cover for the footer -->
          <div style="height: 70px"></div>
        </v-navigation-drawer>
      </div>

      <!-- filters -->
      <div v-if="!editMode" style="display: flex; margin-top: 5px; flex-wrap: wrap">
        <div v-for="category in categories" :key="category.name">
          <v-chip
            @click="filters.category = filters.category == category.name ? '' : category.name"
            label
            class="ml-1 mb-1"
            :dark="filters.category == category.name"
            :outlined="filters.category != category.name"
            >{{ category.name }} ({{ category.count }})</v-chip
          >
        </div>
      </div>

      <!-- actual page -->
      <div style="display: flex">
        <div v-if="editMode" style="">
          <div style="margin-bottom: 10px">
            <v-btn color="error" depressed class="ml-4" @click="deleteList()">Borrar lista</v-btn>
          </div>

          <v-text-field v-model="selectedList.title" outlined label="Title" dense></v-text-field>

          <v-textarea
            label="Lista de ASINs"
            outlined
            v-model="asinsString"
            rows="20"
            :placeholder="'ASIN1\nASIN2'"
            style="width: 200px; margin-top: 20px"
          ></v-textarea>
        </div>

        <!-- ASINs -->
        <div v-if="loading_asins">
          <v-progress-linear indeterminate class="mt-3"></v-progress-linear>
        </div>
        <div v-else-if="!editMode" class="products_wrapper">
          <div v-for="(item, index) in filteredList" :key="index">
            <AsinCard
              v-if="!compactLayout && item.asin && item.visible"
              :asin="item.asin"
              @gotData="gotData($event, item.asin)"
            />
            <AsinCard2
              v-if="compactLayout && item.asin && item.visible"
              :asin="item.asin"
              @gotData="gotData($event, item.asin)"
            />
          </div>

          <!-- let's try out an ad here -->
          <AdSenseFeed />
        </div>
      </div>

      <!-- space to cover for bottom bar -->
      <div style="height: 100px"></div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'
import AsinCard2 from '../components/AsinCard2.vue'
import AsinCard from '../components/AsinCard.vue'
import AdSenseFeed from '@/components/AdSenseFeed.vue'
import { urlParametersAux } from '@/assets/utils/urls'
//import { DateTime } from 'luxon'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    AsinCard,
    AsinCard2,
    AdSenseFeed,
  },

  computed: {
    tab() {
      if (this.selected_id) {
        let t = this.lists.findIndex((x) => x.id == this.selected_id)
        if (t >= 0) return t
      }
      return 0
    },
    /*selectedList() {
      if (this.selected_id) {
        let x = this.lists.find((x) => x.id == this.selected_id)
        if (x) return x
      }
      return { title: 'Selecciona una lista', id: '', asins: [], updatedAt: 0 }
    },*/
    asins() {
      return this.selectedList.asins
    },
    categories() {
      if (!this.selectedList || !this.selectedList.asins) return []

      let output = [] //init with the "unknown category"
      for (const asin in this.metadata) {
        if (!this.selectedList.asins.includes(asin)) continue //notice that metadata has all the accumalated
        if (Object.hasOwnProperty.call(this.metadata, asin)) {
          const item = this.metadata[asin]
          const name = item.category ? item.category : '???'

          if (!output.filter((x) => x.name == name).length) output.push({ name, count: 0 })
          output.filter((x) => x.name == name)[0].count++
        }
      }

      //check for asins with no category (proxy for no data)
      let unknownCat = { name: '???', count: 0 }
      for (let i = 0; i < this.selectedList.asins.length; i++) {
        const asin = this.selectedList.asins[i]
        if (!this.metadata[asin]) {
          unknownCat.count++
        }
      }

      output.sort((a, b) => b.count - a.count)
      output.push(unknownCat) //add the unknown category
      return output
    },
    filteredList() {
      let output = []
      for (let i = 0; i < this.asins.length; i++) {
        const asin = this.asins[i]

        let visible = true

        if (this.metadata[asin]) {
          if (this.filters.category) {
            if (!this.metadata[asin].category || this.metadata[asin].category != this.filters.category) {
              visible = false
            }
          }
        }

        output.push({ asin, visible })
      }
      return output
    },
  },
  watch: {
    compactLayout(newValue) {
      localStorage.compactLayout = newValue
    },
    selected_id(newValue) {
      //this.fetchLists()

      this.filters = { category: '' }
      this.fetchAsins()

      console.log('creating_new_list', this.creating_new_list)
      urlParametersAux.update('id', newValue)
      window.scrollTo(0, 0)
    },

    asins(newvalue) {
      this.asinsString = newvalue.join('\n')
    },
    asinsString(newValue) {
      let asins = newValue.split('\n')
      let uniqueAsins = asins.filter((asin, index) => {
        return asins.indexOf(asin) === index
      })
      if (this.selectedList) this.selectedList.asins = uniqueAsins
    },
  },
  data() {
    return {
      compactLayout: true,
      //asins: [], //[xxx,xxx]
      asinsString: '',
      lists: [], // { title: "PD Jul 2022", id: "pd22-jul" },

      selected_id: null,

      drawer: false,
      editMode: false,

      loading_lists: true,
      loading_asins: false,

      metadata: {},

      selectedList: {
        title: 'Selecciona una lista',
        id: '',
        asins: [],
        updatedAt: 0,
      },

      filters: {
        category: '',
      },

      creating_new_list: false, //ugly but useful way to tell the app when creating a new list, so there is no fetching
    }
  },
  methods: {
    gotData(e, asin) {
      /*console.log(asin, e)*/
      this.metadata[asin] = e
      this.metadata = { ...this.metadata }
    },
    newList() {
      this.creating_new_list = true
      let new_list = {
        id: uuidv4(),
        title: 'Nueva lista',
        asins: [],
        createdAt: new Date().getTime(),
        updatedAt: 0,
      }

      this.selected_id = new_list.id
      this.lists.push(new_list)

      console.log('lists', this.lists)
      this.editMode = true

      //this.creating_new_list = false
    },
    listUpdated(date) {
      //returns {h: hours, t: time, u: units, s: string as t&u}
      let h = Math.abs((new Date() - new Date(date)) / 36e5)
      let x = {}
      if (h < 2) {
        x = { t: Math.round(h * 60), u: 'min' }
      } else if (h < 72) {
        x = { t: Math.round(h), u: 'horas' }
      } else {
        x = { t: Math.round(h / 24), u: 'días' }
      }

      x.h = h
      x.s = x.t + ' ' + x.u

      return x
    },
    async deleteList() {
      let sl = JSON.parse(JSON.stringify(this.selectedList))
      let sure = confirm('Estás seguro de que quieres borrar la lista ""' + sl.title + '"?')
      if (!sure) return
      let x = await api.apiFetch('/lists/deleteList', {
        method: 'POST',
        body: JSON.stringify({
          id: sl.id,
        }),
      })
      if (x.ok) {
        alert('Borrado')
        let i = this.lists.findIndex((x) => x.id == sl.id)
        this.lists.splice(i, 1)
        this.editMode = false
        if (this.lists.length) {
          this.selected_id = this.lists[0].id
        }
      } else {
        alert('Mmmm. Algo ha salido mal')
      }
    },
    async saveAsinList() {
      let x = await api.apiFetch('/lists/updateList', {
        method: 'POST',
        body: JSON.stringify({
          id: this.selectedList.id,
          title: this.selectedList.title,
          asins: this.selectedList.asins,
        }),
      })
      if (x.ok) {
        await this.fetchAsins()
        //alert('Guardado!!')
        this.editMode = false
      } else {
        alert('Oups. Algo ha fallado...\nCreo que no se ha guardado... :(')
        this.editMode = false
      }
    },
    async fetchLists() {
      //this.lists = []
      let x1 = await api.apiFetch('/lists/getLists') //currently fixed in backend lambda
      if (x1.ok) {
        this.lists = await x1.json()
        if (this.lists.length && !this.selected_id) {
          this.selected_id = this.lists[0].id
        }
      }
    },
    async fetchAsins() {
      const id = this.selected_id
      this.loading_asins = true
      let x = await api.apiFetch('/lists/getList?id=' + id)
      if (x.ok) {
        let x2 = await x.json()
        this.selectedList = x2

        //as we got the full list, make sure we refresh the name (in case changed) in the lists list
        let index = this.lists.findIndex((x) => x.id == this.selected_id)
        if (index >= 0) this.lists[index] = JSON.parse(JSON.stringify(x2))
      } else {
        //this.asins = ['cachis']
      }
      this.loading_asins = false
    },
    async fetchAllData() {
      this.loading_lists = true
      await this.fetchLists()
      this.loading_lists = false
      //await this.fetchAsins()
    },
  },
  mounted() {
    this.drawer = !this.$vuetify.breakpoint.mobile
    let x = urlParametersAux.params()

    this.compactLayout = localStorage.compactLayout == 'false' ? false : true

    if (x.id) {
      this.selected_id = x.id
    }
    this.fetchAllData()
  },
}
</script>

<style lang="scss" scoped>
.products_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}
</style>

<template>
  <div>
    <h2>
      Groceries <span class="modern-link" @click="edit = !edit">{{ edit ? 'done' : 'edit' }}</span>
    </h2>

    <!-- loading-->
    <div v-if="loading" style="margin-top: 30px">Waving the magic wand...</div>

    <!-- edit the list (aka input)-->
    <div v-else-if="edit">
      <div style="font-size: 80%; color: grey">Copy-paste your list here</div>
      <v-textarea v-model="input"> </v-textarea>
      <v-btn @click="magic()" depressed color="primary">magic</v-btn>
    </div>

    <!-- the list itself -->
    <div v-else style="margin-top: 5px">
      <!-- summary -->
      <div>
        <div>
          <v-progress-linear :value="(100 * counter.done) / counter.total"></v-progress-linear>
        </div>
        <div style="font-size: 70%; text-align: right">{{ counter.done }} / {{ counter.total }}</div>
      </div>
      <v-list>
        <v-list-group v-for="(category, index) in data" :key="index" v-model="category.active" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                :class="{ done: category.items.filter((x) => x.checked == false).length ? false : true }"
              >
                <b>{{ category.emoji }} {{ category.category_title }}</b>
                <span style="font-size: 70%">
                  ({{ category.items.filter((x) => x.checked == true).length }}/{{ category.items.length }})
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="item in category.items" :key="item.title" style="cursor: pointer">
            <v-list-item-content @click="item.checked = !item.checked" :class="{ done: item.checked }">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.comment" :class="{ done: item.checked }">{{
                item.comment
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="false">
              <v-checkbox v-model="item.checked" style="margin-right: 0px !important"></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
      <div style="font-size: 70%; color: grey">updatedAt: {{ updatedAt }}</div>
    </div>
    <AdSenseFeed style="max-width: 100%; margin-top: 20px" />
  </div>
</template>

<script>
import AdSenseFeed from '@/components/AdSenseFeed.vue'
import api from '@/assets/api'
export default {
  components: {
    AdSenseFeed,
  },
  data() {
    return {
      loading: false,
      input: '',
      edit: true, //will turn false if there is data
      data: [],
      updatedAt: '',
    }
  },
  computed: {
    counter() {
      const counts = this.data.reduce(
        (acc, category) => {
          category.items.forEach((item) => acc[item.checked ? true : false]++)
          return acc
        },
        { true: 0, false: 0 }
      )
      return { done: counts.true, total: counts.true + counts.false }
    },
  },
  watch: {
    input(newValue) {
      localStorage['groceries-input'] = newValue
    },
    data: {
      handler(newValue) {
        localStorage['groceries-data'] = JSON.stringify(newValue)
        localStorage['groceries-updatedAt'] = new Date().toISOString()
        this.updatedAt = localStorage.getItem('groceries-updatedAt')
      },
      deep: true,
    },
  },

  methods: {
    async magic() {
      const body = this.input
      if (!body) return
      this.loading = true
      let x = await api.apiFetch('/groceries/organize-list', {
        method: 'POST',
        body: body,
      })
      if (x.ok) {
        let j = await x.json()
        this.data = j.groceryList
        this.loading = false
        this.edit = false
      }
      this.loading = false
    },
  },

  mounted() {
    this.input = localStorage.getItem('groceries-input')
    this.updatedAt = localStorage.getItem('groceries-updatedAt')
    let d = localStorage.getItem('groceries-data')
    if (d) {
      this.data = JSON.parse(d)
      this.edit = false
    }
  },
}
</script>

<style lang="scss" scoped>
.done {
  text-decoration: line-through;
  color: #ccc !important;
}
.v-list-group__items > .v-list-item {
  padding-left: 40px !important;
}
</style>

<template>
  <div>
    <div style="display: flex">
      <div class="column">
        <p style="margin-bottom: 5px">
          <b>Pega aquí tus links o ASINs </b>
          <span class="modern-link" @click="userInput = ''">borrar </span>
        </p>

        <p style="font-size: 70%; color: rgb(100, 100, 100)">
          Puedes usar
          <a href="https://chrome.google.com/webstore/detail/copy-all-urls/djdmadneanknadilpjiknlnanaolmbfk?hl=en"
            >esta extensión de Chrome</a
          >
          para copiar todas las URLs que tengas abiertas.
        </p>
        <textarea
          @input="parseInput"
          outlined
          v-model="userInput"
          :placeholder="'https://www.amazon.es/dp/B09TBGHBMC\nhttps://www.amazon.es/dp/B34TBGHXYZ'"
          rows="10"
          style="
            font-size: 10pt;
            white-space: pre;
            overflow-wrap: normal;
            overflow-x: auto;
            border: 1px solid #ccc;
            padding: 3px;
            width: 100%;
          "
        ></textarea>

        <v-checkbox @change="parseInput" label="Aleatorio" v-model="randomOrder"></v-checkbox>

        <v-select outlined dense :items="trackingIds" v-model="trackingId" label="Tracking ID"></v-select>

        <!-- Ad at the end of this column: -->
        <AdSenseFeed style="max-width: 400px" />
      </div>
      <div class="column" v-if="asins.length">
        <p>
          {{ userInput ? userInput.split('\n').length : 0 }} línea(s), {{ asins.length }} ASIN(s) diferentes encontrados
        </p>

        <v-textarea
          label="Lista de ASINs"
          @focus="$event.target.select()"
          outlined
          :value="asins.join('\n')"
          rows="5"
          :placeholder="'ASIN1\nASIN2'"
        ></v-textarea>

        <v-textarea
          label="WordPress"
          @focus="$event.target.select()"
          outlined
          :value="shortCode"
          :placeholder="'[amazon box=\'ASIN1, ASIN2\' grid= \'2\' tracking_id=\'' + trackingId + '\]'"
          rows="5"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import pasalo from '@/assets/pasalo'
import AdSenseFeed from '@/components/AdSenseFeed.vue'
export default {
  components: {
    AdSenseFeed,
  },
  data() {
    return {
      randomOrder: false,
      userInput: '',
      asins: [],
      trackingIds: ['cmofertasdeldia-21', 'test'],
      trackingId: 'cmofertasdeldia-21',
    }
  },
  watch: {
    userInput() {
      this.parseInput()
    },
  },
  computed: {
    shortCode() {
      if (this.asins.length == 0) return ''
      return `[amazon box="${this.asins.join(', ')}" grid="2" tracking_id="${this.trackingId || this.trackingIds[0]}"]`
    },
  },
  methods: {
    parseInput() {
      let input = this.userInput
      let lines = input.split('\n')
      let asins = []
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        const asinsInLine = pasalo.findAsin(line)
        console.log('asins in line', asinsInLine)
        if (asinsInLine.length > 0) {
          const asin = asinsInLine[0]

          if (asin && !asins.includes(asin)) {
            asins.push(asin)
          }
        }
      }
      if (this.randomOrder) {
        asins = asins.sort(() => 0.5 - Math.random())
      }
      this.asins = asins
    },
  },
}
</script>

<style lang="scss" scoped>
.column {
  width: 100%;
  margin: 10px;
}
</style>

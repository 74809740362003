<template>
  <div>
    <v-dialog v-model="seeJson" width="500">
      <v-card>
        <v-card-title> Input JSON </v-card-title>
        <v-card-subtitle class="pt-1">The JSON version of your file</v-card-subtitle>
        <v-card-text>
          <v-textarea
            outlined
            style="font-family: 'Courier New', Courier, monospace; font-size: 70%; line-height: 1.8"
            v-model="dataString"
            rows="10"
            :grow="false"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-navigation-drawer v-model="showMenu" left permanent app width="300" :mini-variant.sync="mini">
      <v-list-item two-line class="px-2">
        <v-list-item-avatar>
          <v-img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEGonHzqkpBF2phH6mcsxSs-YqoDGbrE10qHcHlUFY7fq9qFkZMe1Au_H71fnLWdjYZEw&usqp=CAU"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Sublime Completions</v-list-item-title>
          <v-list-item-subtitle
            ><span class="modern-link" @click="seeJson = !seeJson">{{ seeJson ? 'Hide JSON' : 'Show JSON' }}</span>
            |
            <span class="modern-link" @click="downloadJson">Download JSON</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item v-if="false">
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Sublime Completions </v-list-item-title>
          <v-list-item-subtitle>
            Let's make your file readable!
            <span class="modern-link" @click="seeJson = !seeJson">{{ seeJson ? 'Hide JSON' : 'Show JSON' }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <!-- recap -->
        <div>
          <v-list-item v-if="mini">
            <v-list-item-icon style="cursor: pointer">
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <!-- search-->

          <v-text-field
            v-if="!mini"
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            label="search"
            outlined
            v-model="search"
            hide-details
          ></v-text-field>

          <div v-if="!mini">
            <div style="font-size: 80%; margin-top: 5px; margin-bottom: 5px">
              Your file has {{ data.completions.length }} completions
            </div>

            <!-- collapse / add -->
            <div>
              <span class="modern-link" @click="toggleOpenAll">{{
                openItems.length ? 'Collapse all' : 'Expand all'
              }}</span>
              |
              <span class="modern-link" @click="addItem">New item</span>

              <span v-if="activeItems.length" class="modern-link" style="color: red" @click="deleteItem">
                | Delete selected</span
              >
            </div>
          </div>
        </div>
      </v-list>

      <!-- tree! -->
      <v-treeview
        v-if="!mini"
        :open.sync="openItems"
        :active.sync="activeItems"
        hoverable
        activatable
        open-on-click
        :search="search"
        :items="groupedData"
        @update:active="onClickTreeItem($event)"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="item.folder">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon v-else>
            {{ 'mdi-file-code-outline' }}
          </v-icon>
        </template>

        <template v-slot:label="{ item }">
          <div v-if="!item.folder">
            {{ item.name }}
          </div>
          <div v-else>
            {{ item.name }}
          </div>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <ComponentEditor v-model="data.completions[index]" />

    <p style="font-size: 80%; margin-top: 30px">
      More info:
      <a href="https://www.sublimetext.com/docs/completions.html" target="_blank"
        >https://www.sublimetext.com/docs/completions.html</a
      >
    </p>

    <AdSenseFeed />

    <div v-if="false" style="margin-top: 30px">
      This should go into a file
      <span style="color: darkred; font-family: consolas; font-size: 90%">whatever.sublime-completions</span>
      that looks like:
      <div>
        <pre style="padding: 5px; white-space: pre-wrap; font-size: 70%">
{
  "scope": "source.sql -constan.other.table-name.sql",
  "completions": [
    ...
  ]
}
                
              </pre
        >
      </div>
    </div>
  </div>
</template>

<script>
import AdSenseFeed from '@/components/AdSenseFeed.vue'
import ComponentEditor from './ComponentEditor.vue'
export default {
  components: {
    ComponentEditor,
    AdSenseFeed,
  },

  data() {
    return {
      mini: false,
      seeJson: false,
      search: '',
      index: 0,
      openItems: [],
      activeItems: [],
      showMenu: true,
      dataString: '{scope: "", completions: [{"trigger":"trigger"}]}',
      data: { scope: '', completions: [{ trigger: 'hey' }] },
      item: {},
    }
  },
  watch: {
    search(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.expandAll()
      }
    },
    item: {
      deep: true,
      handler(newValue) {
        this.jsonString = JSON.stringify(newValue)
      },
    },
    jsonString(newValue) {
      let x = JSON.parse(newValue)
      let t = x.trigger
      if (t.includes('\t')) {
        x.annotation = t.split('\t')[1]
        x.trigger = t.split('\t')[0]
      }
      this.item = x
    },
    data: {
      deep: true,
      handler(newValue) {
        this.dataString = JSON.stringify(newValue)
      },
    },
    dataString(newValue) {
      let x = JSON.parse(newValue)
      this.data = x
      localStorage.sublimeCompletionFile = newValue
    },
  },
  computed: {
    groupedData() {
      const items = this.data.completions

      let x = []
      for (let i = 0; i < items.length; i++) {
        let item = JSON.parse(JSON.stringify(items[i])) //make sure it's a copy
        item.id = i //we are calling id the original index, so we can use it on click
        item.name = item.trigger
        let group = item.group ? item.group : 'other'

        let auxx = x
        for (let j = 0; j < group.split('.').length; j++) {
          const g = group.split('.')[j]
          const gid = 'group:' + g

          let k = auxx.findIndex((u) => u.id == gid)
          if (k == -1) {
            auxx.push({ id: gid, name: g, folder: true, children: [] })
          }
          k = auxx.findIndex((u) => u.id == gid)
          auxx = auxx[k].children
        }
        auxx.push(item)
      }

      return x
    },
  },
  methods: {
    downloadJson() {
      let link = document.createElement('a')
      const file = new Blob([this.dataString], { type: 'text/plain' })
      link.href = URL.createObjectURL(file)
      link.download = 'common_functions_sql_sopena.sublime-completions'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    onClickTreeItem(event) {
      //event is the array with active items, we only allow one so position zero
      if (event.length == 1) {
        this.index = event[0]
      } else if (event.length == 0) {
        this.index = -1
      } else if (event.length > 1) {
        alert("shouldn't have more than 1 item selected ( you have " + event.length + ')...')
      }
    },
    deleteItem() {
      if (this.activeItems.length == 1) {
        let selectedIndex = this.activeItems[0]
        this.data.completions.splice(selectedIndex, 1)
        this.activeItems = [] //don't let any item selected
      } else {
        console.warn('Unexpected 230131')
      }
    },
    addItem() {
      //when an item is selected and visible, we use the same group for the new item, and add right after it  in the array
      //if not, we add to the "other" group at the end of the array
      let item = { group: 'other' }
      let index = this.data.completions.length //will add to the end by default

      //check if active and visible item, so we add the new right after it
      if (this.activeItems.length == 1) {
        let selectedIndex = this.activeItems[0]
        let selectedGroup = this.data.completions[selectedIndex].group
        if (selectedGroup && this.openItems.includes('group:' + selectedGroup)) {
          item.group = selectedGroup
          index = selectedIndex + 1
        }
      }

      if (!this.openItems.includes('group:' + item.group)) {
        this.openItems.push('group:' + item.group)
      }

      this.data.completions.splice(index, 0, item)
      this.activeItems = [index]
      this.index = index
    },
    expandAll() {
      let x = []
      const completions = JSON.parse(JSON.stringify(this.data.completions))
      for (let i = 0; i < completions.length; i++) {
        const c = completions[i]
        let g = c.group
        if (!g) g = 'other'

        for (let j = 0; j < g.split('.').length; j++) {
          const k = 'group:' + g.split('.')[j]

          if (!x.includes(k)) x.push(k)
        }
      }
      this.openItems = x
    },
    collapseAll() {
      this.openItems = []
    },
    toggleOpenAll() {
      if (this.openItems.length) {
        this.collapseAll()
      } else {
        this.expandAll()
      }
    },
  },
  mounted() {
    let x = localStorage.sublimeCompletionFile
    if (x) this.dataString = x
  },
}
</script>

<style lang="scss" scoped></style>

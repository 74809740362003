<template>
  <v-card max-width="500">
    <v-card-title
      >Edit event

      <v-spacer> </v-spacer>
      <v-btn color="error" icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>

    <v-card-text>
      <v-text-field
        dense
        v-model="draft.summary"
        hide-details
        outlined
        class="mb-2"
        clearable
        label="Title"
      ></v-text-field>
      <v-textarea
        dense
        rows="1"
        auto-grow
        v-model="draft.location"
        hide-details
        outlined
        class="mb-2"
        clearable
        label="Location"
      ></v-textarea>
      <div style="margin-bottom: 8px">
        Tag:
        <ChipDropdown
          v-model="draft.alex.tag"
          :items="[
            '👨‍⚕️ pediatra',
            '🏫 colegio',
            '👶 guarde',
            '🤰 gine',
            '🎁 anniversary',
            '🏥 hospital',
            '🍺 social',
            '✏️ papeleo',
          ]"
        />
      </div>
      <v-textarea
        dense
        rows="2"
        auto-grow
        v-model="draft.alex.description"
        hide-details
        outlined
        class="mb-1"
        clearable
        label="Description"
      ></v-textarea>

      <!-- date time -->
      <div>
        <v-checkbox v-model="allday" label="All day">All day</v-checkbox>
        <DateTimeEdit v-model="draft.start" :allday="allday" label="Start Date" />
        <DateTimeEdit v-model="draft.end" :allday="allday" label="End Date" />
      </div>

      <p class="subtext" v-if="draft.creator && draft.creator.email">Creator: {{ draft.creator.email }}</p>
    </v-card-text>

    <v-card-actions>
      <v-btn color="error" text @click="close()">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save(draft)">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ChipDropdown from '@/components/ChipDropdown.vue'
import api from '@/assets/api'
import DateTimeEdit from './DateTimeEdit.vue'
export default {
  components: {
    DateTimeEdit,
    ChipDropdown,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    value(newValue) {
      this.draft = { ...newValue }
    },
    draft(newValue) {
      this.$emit('change', newValue)
      this.allday = newValue.start.date ? true : false
    },
  },
  computed: {},
  data() {
    return {
      draft: {
        alex: {},
      },
      allday: false,
    }
  },
  methods: {
    onDateChanage(newDate) {
      console.log(newDate)
    },
    close() {
      this.$emit('close')
    },

    async save(item) {
      let path = item.id ? '/gcalendar/update' : '/gcalendar/create'

      //fix end date (for events that last one day, end date is start date + 1, not same)
      if (this.allday && item.start.date == item.end.date) {
        let ed = new Date(item.end.date)
        ed.setDate(ed.getDate() + 1)
        item.end.date = ed.toISOString().substring(0, 10)
      }
      // end fix date

      let itemCopy = { ...item }

      //move "alex" to description
      if (!item.alex) {
        item.alex = { description: item.description || '' }
      }
      item.description = JSON.stringify(item.alex)
      delete item.alex
      //end move "alex"

      let x = await api.apiFetch(path, {
        method: 'POST',
        body: JSON.stringify(item),
      })
      if (x.ok) {
        let j = await x.json()
        this.$emit('saved', itemCopy)
        this.$emit('close')
      } else {
        this.$emit('error')
      }
    },
  },
  mounted() {
    this.draft = this.value
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date1"
          :label="label"
          :prepend-icon="outlined ? '' : 'mdi-calendar'"
          :prepend-inner-icon="outlined ? 'mdi-calendar' : ''"
          :outlined="outlined"
          :dense="dense"
          readonly
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        ></v-text-field>
      </template>
      <v-date-picker id="form-date-picker" v-model="date1" no-title scrollable @input="menu = false" :min="min">
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    min: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menu: false,
      date1: undefined,
    }
  },
  watch: {
    value(newValue) {
      this.date1 = newValue
    },
    date1(newValue) {
      console.log('date1', newValue)
      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
  },
  methods: {
    today() {
      let d = new Date()
      return d.getFullYear() + '-' + this.pad(d.getMonth() + 1) + '-' + this.pad(d.getDate())
    },
    pad(x) {
      if (x < 10) x = '0' + x
      return '' + x
    },
  },
  beforeMount() {
    this.date1 = this.value ? this.value : this.today()
  },
}
</script>

<style lang="scss">
#form-date-picker * th {
  color: grey !important;
}
</style>

<template>
  <div>
    <div>
      <div v-for="item in items" :key="item.id" class="quote">
        <div class="quote-text">
          {{ item.quote }}
        </div>

        <div class="quote-author">
          {{ item.author }}
        </div>

        <div class="quote-actions">
          <div>
            <span @click="editMe(item)">edit</span>
          </div>
          <div>
            <span @click="remove(item.id)">delete</span>
          </div>
        </div>
      </div>
    </div>

    <!-- buffer space -->
    <div style="height: 60px"></div>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog v-if="edit" v-model="edit" scrollable max-width="600">
      <v-card>
        <v-card-title>Quote</v-card-title>
        <v-card-text>
          <QuoteEdit :id="editItem.id" @change="onChange($event)" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="edit = false" depressed color="error">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save" depressed color="success">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/assets/api'
import QuoteEdit from './QuoteEdit.vue'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
    QuoteEdit,
  },
  data() {
    return {
      loading: false,
      items: [],
      editItem: {},
      edit: false,
    }
  },
  methods: {
    async remove(id) {
      let sure = confirm('You sure?')
      if (!sure) return
      let i = this.items.findIndex((x) => x.id == id)
      this.loading = true
      let x = await api.apiFetch('/quotes/delete/' + id)
      this.loading = false
      if (x.ok) {
        console.log('removed')
        this.items.splice(i, 1)
      } else {
        alert('error')
      }
    },
    onChange(item) {
      this.editItem = { ...item }
    },
    editMe(item) {
      this.editItem = item
      this.edit = true
    },
    async save() {
      this.loading = true
      if (!this.editItem) this.editItem = {}
      if (!this.editItem.id) {
        this.editItem.id = uuidv4()
      }
      let x = await api.apiFetch('/quotes/put', {
        method: 'POST',
        body: JSON.stringify(this.editItem),
      })
      if (x.ok) {
        console.log('saved')
      } else {
        alert('error')
      }
      let i = this.items.findIndex((x) => x.id == this.editItem.id)
      if (i == -1) {
        this.items.push({ ...this.editItem })
      } else {
        this.items[i] = { ...this.editItem }
      }

      this.items = [...this.items] //JSON.parse(JSON.stringify(this.items))

      this.editItem = {}
      this.loading = false
      this.edit = false
    },
    async addNewItem() {
      this.editItem = {} //TODO: probably worth fine tuning so we can continue editing same value after pressed cancel for new items.
      this.edit = true

      console.log('not ready yet')
    },
    async fetchAll() {
      this.loading = true
      let x = await api.apiFetch('/quotes/getall')
      if (x.ok) {
        this.items = await x.json()
      }
      this.loading = false
    },
  },
  mounted() {
    this.fetchAll()
  },
}
</script>

<style scoped>
.quote {
  padding: 10px;
  margin: 4px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.quote-raw {
  font-size: 60%;
  font-family: 'Courier New', Courier, monospace;
}

.quote-text {
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.quote-author {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80%;
  text-align: right;
}

.quote-actions {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;

  font-family: 'Courier New', Courier, monospace;
  cursor: pointer;
}
</style>

<template>
  <div>
    <div v-if="false">
      {{ id }}
      <br />
      {{ item }}
    </div>
    <!-- type -->
    <div>
      <div v-if="!item.type" style="height: 40vh"></div>
      <v-select label="Tipo" :items="types" v-model="item.type"></v-select>
    </div>

    <!-- both -->
    <div v-if="item.type">
      <!-- image and assistant input -->
      <div style="margin: 5px 0px 25px">
        <input type="file" id="imageInput" accept="image/*,application/pdf" @change="onFileChange($event)" />
        <img :src="thumbnail" style="max-width: 50px" />
        <div v-if="item.assistant && item.assistant.assistant_message" style="margin: 10px 0px">
          {{ item.assistant.assistant_message }}
        </div>
        <div v-if="loading_assistant" style="margin: 10px 0px">
          <v-progress-circular indeterminate size="20" color="primary" width="1"></v-progress-circular>
          Analizando la imagen...
        </div>
      </div>

      <!-- information -->
      <FormDatePicker label="Fecha" v-model="item.date"></FormDatePicker>
      <v-select v-model="item.title" :items="options.title[item.type]" :label="labels.title[item.type]"></v-select>

      <v-text-field
        v-model="item.base_imponible"
        label="Base imponible"
        hide-details
        suffix="EUR"
        type="number"
      ></v-text-field>
      <v-row>
        <v-col>
          <v-text-field v-model="item.tipo_iva" label="Tipo IVA" suffix="%" type="number"></v-text-field>
        </v-col>
        <v-col>
          <v-select
            v-model="item.tipo_retencion"
            label="Retención"
            suffix="%"
            :items="options.retencion[item.type]"
            :hint="hints.tipo_retencion[item.type]"
          ></v-select>
        </v-col>
      </v-row>

      <v-text-field v-model="item.numero_factura" label="Nº factura" hide-details></v-text-field>
      <v-text-field v-model="item.nif" :label="labels.nif[item.type]" hide-details></v-text-field>
      <v-text-field v-model="item.nombre" :label="labels.nombre[item.type]"></v-text-field>

      <!-- In the excel description is used only for ingresos, I think. -->
      <v-textarea v-model="item.descripcion" label="Descripción" rows="1" auto-grow></v-textarea>

      <v-textarea v-model="item.comentarios" label="Comentarios" rows="1" auto-grow></v-textarea>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'
import FormDatePicker from '@/components/FormDatePicker.vue'

export default {
  components: {
    FormDatePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading_assistant: false,
      types: [
        { text: 'Ingreso', value: 'ingreso' },
        { text: 'Gasto', value: 'gasto' },
      ],
      labels: {
        title: {
          ingreso: 'Concepto',
          gasto: 'Tipo Gasto',
        },
        nif: {
          ingreso: 'NIF Cliente',
          gasto: 'NIF Proveedor',
        },
        nombre: {
          ingreso: 'Nombre Cliente',
          gasto: 'Nombre Proveedor',
        },
        description: {
          ingreso: 'Descripción Servicio u Operación',
          gasto: '', //n/a
        },
      },
      hints: {
        tipo_retencion: {
          ingreso:
            'Es el Tipo de Retención que debes aplicar en las facturas que hagas a otros Autónomos o Empresas (15%, o 7% si eres nuevo Autónomo). Es decir, dinero que restas a tus facturas y que deben avanzar ellos a Hacienda por ti, en concepto de IRPF',

          gasto:
            'Es el Tipo de Retención (15%, o 7% para nuevos Autónomos) que te pondran aquellos Autónomos que te emitan facturas a ti, ya que por ser tu también Autónomo, estás obligado a avanzar ese dinero a Hacienda en concepto del IRPF del Autónomo que te presta los servicios, por lo tanto él está obligado a restártelo en factura. Parecen conceptos complicados pero no lo son tanto!',
        },
      },
      options: {
        title: {
          ingreso: ['Prestación de servicios', 'Venta de productos'],
          gasto: [
            'Servicios de empresas',
            'Compra de productos',
            'Cuota autónomo',
            'Transportes',
            'Suministros',
            'Arrendamiento',
            'Gastos exentos de IVA',
            'Restaurantes',
            'Otro',
          ],
        },
        retencion: {
          gasto: [7, 15],
          ingreso: [7, 15],
        },
      },
      item: {
        assistant: {},
        type: '',
        date: new Date().toISOString().slice(0, 10),
        numero_factura: '',
        base_imponible: '',
        tipo_iva: '',
        tipo_retencion: '',
        title: '',
        descripcion: '',

        nif: '',
        nombre: '',
        comentarios: '',
      },

      thumbnail: '',
      file: {
        mimetype: '',
        base64: '',
      },
    }
  },
  watch: {
    id() {
      this.fetchItem()
    },
    item: {
      deep: true,
      handler(newValue) {
        console.log('changed', newValue)
        this.$emit('change', newValue)
      },
    },
  },
  methods: {
    async onFileChange(event) {
      console.log('onFileChange', event)
      const file = event.target.files[0] // Get the first selected file
      if (!file) {
        return
      }

      if (file.type.startsWith('image/') || file.type === 'application/pdf') {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.thumbnail = e.target.result
          this.file.mimetype = e.target.result.split(';')[0].split(':')[1]
          this.file.base64 = e.target.result.split(',')[1] // Get the actual base64 string

          this.handleFile()
        }

        reader.readAsDataURL(file)
      } else {
        alert('Please upload an image or a PDF.')
      }
    },

    async handleFile() {
      this.loading_assistant = true
      const file = { ...this.file }
      let x = await api.apiFetch('/autonomo/vision', {
        method: 'POST',
        'content-type': file.mimetype,
        body: JSON.stringify({
          file,
          id: this.id,
        }),
      })
      if (x.ok) {
        const data = await x.json()
        this.item.assistant = data.assistant
        this.item.file = data.s3_file
        for (const k in data.assistant) {
          if (Object.hasOwnProperty.call(data.assistant, k)) {
            if (this.item[k] !== undefined) {
              this.item[k] = data.assistant[k]
            }
          }
        }
      }
      this.loading_assistant = false
    },
    async fetchItem() {
      let x = await api.apiFetch('/autonomo/get/' + this.id, {})
      if (x.ok) {
        let item = await x.json()
        if (!item.ingreso) item.ingreso = {}
        if (!item.gasto) item.gasto = {}
        this.item = item
      } else {
        console.warn('No suh quote', this.id)
      }
    },
  },
  mounted() {
    console.log('mojunted')
    this.fetchItem()
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div style="max-width: 900px; margin: auto">
    <!-- serch text -->
    <div>
      <v-text-field
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        class="mb-1"
        hide-details
      ></v-text-field>
    </div>

    <!-- filter by meals -->

    <!--chips -->
    <div style="background-color: #fafafa; padding: 7px 0px; border-bottom: 1px solid #ccc; border-top: 1px solid #ccc">
      <div
        @click="showFilters = !showFilters"
        style="width: 100%; cursor: pointer; display: flex; justify-content: space-between"
      >
        <div style="margin-top: 6px">Filtros</div>
        <v-btn icon text>
          <v-icon>{{ showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </div>
      <v-expand-transition>
        <div v-show="showFilters">
          <div style="margin-top: 4px">
            <div v-for="(v, k) in chips" :key="k" style="margin-bottom: 10px">
              <v-chip
                v-for="chip in v"
                :key="chip.value"
                class="mr-1 mb-1"
                small
                :outlined="filters[k] != chip.value"
                @click="filters[k] = filters[k] == chip.value ? '' : chip.value"
                >{{ chip.value || '???' }} ({{ chip.count }})</v-chip
              >
            </div>
          </div>

          <div>
            <ChipDropdown small :items="['Sí', 'No', 'Todo']" label="Ana" v-model="filterAna" />
            <ChipDropdown
              small
              :items="['title', 'createdAt', 'updatedAt', 'aleatorio']"
              label="Ordenar por"
              v-model="sortBy"
            />
            <ChipDropdown v-if="sortBy != 'aleatorio'" small :items="['asc', 'desc']" label="" v-model="sortOrder" />
          </div>
        </div>
      </v-expand-transition>
    </div>

    <div style="margin-top: 4px">
      {{ filteredList.length + ' resultados' }}
      <span class="modern-link" @click="fetchData">Recargar</span>
    </div>

    <div>
      <router-link to="/food/menus">Menus</router-link>
    </div>

    <div v-if="loading">
      <v-progress-circular
        style="position: fixed; left: calc(50% - 40px); top: calc(50% - 40px)"
        :size="80"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-list dense>
        <v-list-item v-for="item in filteredList" :key="item.id" :to="'/food/item/' + item.id" class="no-underline">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ item.meal ? item.meal.join('/') : '' }} | {{ item.content }} |
              {{ item.ana ? 'Ana' : 'No Ana' }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <!-- buffer space -->
    <div style="height: 60px"></div>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import api from '@/assets/api'
import utils from '@/assets/utils'
import { v4 as uuidv4 } from 'uuid'

import ChipDropdown from '@/components/ChipDropdown.vue'
export default {
  components: {
    ChipDropdown,
  },
  data() {
    return {
      searchText: '',
      loading: false,
      editId: false,
      showForm: false,
      sortBy: 'aleatorio',
      sortOrder: 'asc',
      filterAna: 'Todo',
      showFilters: false,
      filters: {
        content: '',
        order: '',
        meal: '',
        tags: '',
      },
      items: [],
      foods: [],
    }
  },
  watch: {
    sortBy(n) {
      if (n == 'createdAt' || n == 'updatedAt') {
        this.sortOrder = 'desc'
      } else {
        this.sortOrder = 'asc'
      }
    },
  },
  computed: {
    chips() {
      let x = {}

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]

        for (const field in this.filters) {
          if (!x[field]) x[field] = []

          let v1 = item[field]
          if (!v1) v1 = 'None'
          let values = Array.isArray(v1) ? v1 : [v1]
          if (!values.length) values = ['None']

          for (let j = 0; j < values.length; j++) {
            const v = values[j]
            if (!x[field].find((a) => a.value == v)) x[field].push({ value: v, count: 0 })

            if (this.filteredList.find((a) => a.id == item.id)) {
              x[field].find((a) => a.value == v).count++
            }
          }
        }
      }

      return x
    },

    filteredList() {
      let x = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        let search_in = [item.title, item.description, item.content, item.tags ? item.tags.join(' ') : ''].join(' ')

        let add1 = utils.search.searchMatch(this.searchText, search_in)

        let chipAdd = true
        for (const field in this.filters) {
          const filter = this.filters[field]
          if (!filter) continue
          let v1 = item[field]
          if (!v1) v1 = 'None'
          const v = Array.isArray(v1) ? v1 : [v1]
          if (!v.includes(filter) || (filter == 'None' && !v.length)) {
            chipAdd = false
          }
        }

        //ana
        let addAna = true
        if (this.filterAna == 'No' && item.ana) addAna = false
        if (this.filterAna == 'Sí' && !item.ana) addAna = false

        if (add1 && chipAdd & addAna) {
          x.push(item)
        }
      }

      if (this.sortBy) {
        if (this.sortBy == 'aleatorio') {
          x.sort(() => Math.random() - 0.5)
        } else if (this.sortOrder == 'asc') {
          x.sort((a, b) => (a[this.sortBy] ? a[this.sortBy].localeCompare(b[this.sortBy]) : 0))
        } else {
          x.sort((a, b) => (b[this.sortBy] ? b[this.sortBy].localeCompare(a[this.sortBy]) : 0))
        }
      }
      return x
    },
    meals() {
      let x = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        if (!x.includes(item.meal)) x.push(item.meal)
      }
      return x
    },
  },

  methods: {
    onClick(item) {
      this.editId = item.id
      this.showForm = true
    },
    /*getRandomId() {
      const randomId = Math.random().toString(36).substr(2, 9)
      return randomId
      return uuidv4()
    },*/
    addNewItem() {
      console.log('New item')
      // Add logic to add new item to your data source here
      // Reset form fields and close dialog
      this.editId = null
      this.editId = uuidv4() // this.getRandomId()
      this.$router.push('/food/item/' + this.editId)
    },

    formatItems() {
      let x = []
      for (let i = 0; i < this.foods.length; i++) {
        let food = this.foods[i]
        food.id = Math.random().toString(36).substr(2, 9)

        x.push(food)
      }
      return x
    },
    async fetchData() {
      this.loading = true
      let x = await api.apiFetch('/food/getall')
      if (x.ok) {
        this.items = await x.json()
      }
      this.loading = false
    },
  },
  mounted() {
    this.fetchData() //this.formatItems()
  },
}
</script>

<style scoped>
.no-underline:hover {
  text-decoration: none;
}
</style>

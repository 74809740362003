<template>
  <div>
    <div v-if="page == 1">
      <p>Querídisima Rocío,</p>

      <p>
        Melchor y Gaspar estaban un poco moscas por lo de la pulsera. Ya sabes que no les gusta nada andar gestionando
        devoluciones, y como mencionan en su carta decidieron regalárte la pulsera igualmente, a ver si aprendías.
      </p>

      <p>
        Pero ya sabes que yo tengo debilidad por ti: Desde que eras pequeñita disfruto viéndote cantar, reir, jugar,
        competir...
      </p>
      <p>
        Y bueno, además el nuevo becario del equipo de pajes no paraba de insistir en que te cuidara con especial
        esmero. De hecho, ya no es becario porque acaba de promocionar: es un crack, nos está ayudando muchísimo, y
        disfruta más que los niños. Es más, estamos pensando ponerle una carroza el año que viene en las cabalgatas. Si
        no se hubiera inventado ya Papá Noel (que le pega bastante), lo inventaríamos ahora para darle más protagonismo.
      </p>
      <p>Total, que no he podido quedarme de brazos cruzados y he recurrido a mi magia más antigua:</p>

      <p>
        Como has podido observar, he reemplazado la pulsera por un código QR. Este sistema moderno me permite escribirte
        sin que se enteren Melchor y Gaspar. Al deshacerme de la pulsera... ¡ya no tienes que buscar un primo que herede
        una de tus 3 pulseras Xiaomi Mi Band 4!
      </p>

      <p>Y aunque solo eso sería suficiente magia, no he querido detenerme ahí.</p>

      <p>¿Llevas puesta tu pulsera actual? Si no, póntela antes de seguir leyendo.</p>

      <a href="#" @click="page = 2">¡Sí, llevo mi pulsera!</a>
      <br />
      <br />
    </div>
    <div v-if="page == 2">
      <p>¡Perfecto! Vamos allá.</p>
      <p>Ya que tu pulsera original funciona, tengo pensado otro regalo para ti.</p>
      <p>Cuenta los pasos que hay en el pasillo de tu casa, y mete el valor en la siguiente caja</p>

      <v-text-field outlined type="number" v-model="pasillo_pasos"></v-text-field>
      <p v-if="error === true" style="color: red">Nope</p>
    </div>

    <div v-if="page == 3">
      <p>¡Perfecto!</p>
      <p>Ahora, ayúdame con el siguiente acertijo</p>
      <p>
        <i>
          Mi estampa rompióse y las piezas metiéronse en una caja. Empezaste a arreglarme, pero pronto me olvidaste y
          abandonaste indefinidamente. Ahora has de encontrarme, pues poseo la clave para seguir tu viaje.
        </i>
      </p>
      <v-text-field outlined type="text" v-model="solucion_page3"></v-text-field>
      <p v-if="error === true" style="color: red">Nope</p>
    </div>

    <div v-if="page == 4">
      <p>¡Bravo!</p>
      <p>¿Cuantos pasos marca tu pulsera?</p>
      <v-text-field outlined type="number" v-model="pasos_page_4"></v-text-field>
      <div v-if="pasos_page_4 > 0 && pasos_page_4 < 200">
        <p style="color: red">Mmmm, muy pocos me parecen. Ponte a andar hasta que llegues a 200 pasos.</p>
      </div>
      <div v-else-if="pasos_page_4 > 0">
        <p>¡Muy bien, qué bien sienta dar pasitos!</p>
        <p>¿Lista para continuar?</p>
        <p>
          <a href="#" @click="page = 5">Yeah!</a>
        </p>
      </div>
    </div>
    <div v-if="page == 5">
      <p>
        <i> Caminante no hay camino, se hace camino al andar. </i>
      </p>
      <p>
        Que aplicado a nuestro caso es bastante apropiado. Significa que el regalo que te estoy haciendo... ¡son pasos
        para tu pulsera!
      </p>
      <p>Sigue dando pasitos, y avísame cuando llegues a 400 pasos</p>
      <a href="#" @click="page = 6">¡Ya llevo 400 pasos!</a>
    </div>

    <div v-if="page == 6">
      <p>¡Buen trabajo!</p>
      <p>Otro reto.</p>
      <p>
        <i
          >Habiéndolos creado Dios hombre y mujer, el amor mutuo entre ellos se convierte en imagen del amor absoluto e
          indefectible con que Dios ama al hombre. Este amor es bueno, muy bueno, a los ojos del Creador</i
        >
      </p>
      <p>La solución es un número. ¿Cuál?</p>
      <v-text-field outlined type="number" v-model="solucion_page6"> </v-text-field>
      <p v-if="error === true" style="color: red">Nope</p>
    </div>

    <div v-if="page == 7">
      <p>¡Eres una crack! cómo se nota quien ha dado catequesis.</p>
      <p>
        Un último acertijo. Si lo resuelves, te llevará hasta tu premio. Pero recuerda, caminante no hay camino, se hace
        camino al andar.
      </p>

      <p>
        <i>
          Que por mayo era por mayo,<br />
          cuando los reyes muy magos,<br />
          pasaron con regalos...<br />
          uno para tus pasos<br />
        </i>
      </p>

      <p>¡Espero que lo disfrutes!</p>

      <p>Tuyo siempre,</p>
      <p>Baltasar</p>
    </div>
    <!-- bonus-->

    <div v-if="page == 8">
      <div>
        <p>
          En los albores de los tiempos, cuando las estrellas apenas comenzaban a escribir sus historias en el
          firmamento, los Grandes Artesanos del Reino Perdido de Xiomitri, guiados por visiones de un futuro lejano,
          forjaron tres pulseras de poder. Estas joyas, imbuidas con el resplandor de la salud y la fuerza, eran un
          regalo a los mortales, una ayuda en su eterna lucha contra la sombra del ocio y el olvido. Una medía el pulso
          del corazón con la ternura de una madre. La segunda velaba el sueño con la vigilancia de un guardián
          ancestral. La última contaba los pasos con la precisión de los antiguos oráculos.
        </p>

        <p>
          Pero más allá de las montañas del Tiempo, en un reino oculto donde la alquimia y la sabiduría se entrelazan,
          se forjó un artefacto de poder inconmensurable: un objeto cuyo nombre se perdía en los susurros del viento.
          Este artefacto, creado no solo para unificar las virtudes de las Pulseras de Poder, sino para trascenderlas,
          era una joya forjada en la esencia misma del deseo y el destino.
        </p>

        <p>
          En un año marcado por la determinación y la promesa de renacimiento, un año en que los corazones se alzaban en
          busca de salud y fuerza, el destino de este objeto legendario se entrelazó con el de una noble dama. Portadora
          de una pulsera humilde pero fiel, está bella dama había luchado valientemente en la senda del bienestar, pero
          sus pasos anhelaban una guía más poderosa, un poder que le infundiera la fuerza y el ánimo de los antiguos
          héroes.
        </p>

        <p>
          En la noche más mágica, cuando los tres Reyes Magos surcaban los cielos en su viaje eterno, le fue otorgado el
          artefacto único, un regalo envuelto en estrellas y esperanzas. Este objeto, de una elegancia inmarcesible y un
          poder que desafiaba los límites del tiempo, se convirtió en su aliado en la noble cruzada hacia un futuro de
          salud y esplendor.
        </p>

        <p>
          Con este poder en su muñeca, la dama se embarcó en un viaje épico, un viaje de miles de pasos diarios bajo la
          luz del alba y la luna. Cada movimiento, cada latido, cada respiración se convirtió en un acto de valentía,
          una danza en el gran baile de la vida. El objeto único, más que un mero guardián de su salud, se transformó en
          un emblema de su voluntad indomable, un símbolo de su compromiso con la noble causa de nutrir su cuerpo y de
          alimentar su alma.
        </p>

        <p>
          En el horizonte del tiempo, la historia de lesta dama y de su objeto único y legendario se tejió con hilos de
          cobre y led, una saga de determinación y belleza, una canción de amor y poder. Un relato que, en el corazón
          del mundo moderno, aún resuena con la magia y la majestuosidad de los días de antaño, inspirando a todos
          quienes la escuchan a buscar su propio camino hacia la salud y la gloria.
        </p>

        <p>
          Recibe pues bella dama este objeto único, y sirva su vista de recuerdo del amor que por ti tiene, quien para
          ti se lo pidió a los Reyes.
        </p>

        <p>PD: “Un gran poder, conlleva una gran responsabilidad” - Ben Parker</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    pasillo_pasos(newValue) {
      if (newValue == 8) {
        this.page = 3
      }
    },
    solucion_page3(newValue) {
      if (newValue.toLowerCase() == 'como mola baltasar') {
        this.page = 4
        this.error = false
      } else if (newValue == 'Rokio_Guapa') {
        this.page = 8
        this.error = false
      } else if (newValue == '') {
        this.error = false
      } else {
        this.error = true
      }
    },
    pasos_page_4(newValue) {
      if (newValue < 200) {
        this.show_4_2 = false
        this.error = false
      } else {
        this.show_4_2 = true
      }
    },
    solucion_page6(newValue) {
      if (newValue == 1604) {
        this.page = 7
        this.error = false
      } else {
        this.error = true
      }
    },
  },
  data() {
    return {
      page: 1,
      pasillo_pasos: 0,
      solucion_page3: '',
      pasos_page_4: 0,
      show_4_2: false,
      solucion_page6: '',

      error: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <v-menu offset-y :close-on-content-click="true" :nudge-width="40">
    <template #activator="{ on, attrs }">
      <v-chip :small="small" outlined v-bind="attrs" v-on="on" class="ml-1 mt-1">
        <span v-if="label">{{ label }}:&nbsp;</span> {{ selected }}
        <v-icon class="ml-1" small right> mdi-chevron-down </v-icon>
      </v-chip>
    </template>

    <v-list class="elegant-list" dense>
      <v-list-item v-for="(item, index) in items" :key="index" @click="selectItem(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ChipDropdown',
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    small: {
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selected: this.value || '',
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    },
  },
  methods: {
    selectItem(item) {
      this.selected = item
      this.$emit('input', item)
    },
  },
}
</script>

<style scoped>
.elegant-list {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>

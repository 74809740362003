var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-width":"500px","margin":"5px"}},[_c('a',{staticStyle:{"color":"inherit","text-decoration":"inherit"},attrs:{"href":_vm.open_url,"target":'window-' + this.asin}},[(_vm.asin && _vm.data && _vm.data.date)?_c('div',{staticClass:"product_card"},[_c('h3',[_vm._v(_vm._s(_vm.data.title ? _vm.data.title.substr(0, 100) : 'Error'))]),_c('table',[_vm._m(0),_vm._m(1),_c('tbody',[_c('tr',[_c('td',[_c('div',[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.tweakImage(_vm.data.image_url),"alt":_vm.data.title}})])]),_c('td',{attrs:{"min-width":"200px"}},[_c('div',{staticClass:"card_area",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","top":"3px","right":"3px","z-index":"99999"}}),_c('div',[_c('span',{staticStyle:{"font-size":"185%"}},[_vm._v(_vm._s(_vm.formatMoney(_vm.data.current_price)))]),_vm._v("  "),(_vm.data.old_price && _vm.data.old_price != 'n/a')?_c('span',{staticClass:"strikeout",staticStyle:{"font-size":"90%"}},[_vm._v(_vm._s(_vm.formatMoney(_vm.data.old_price)))]):_vm._e()]),(_vm.data.descuento && _vm.data.descuento != 'n/a')?_c('div',{staticStyle:{"margin-top":"8px"}},[_c('span',{staticStyle:{"font-size":"80%"}},[_vm._v(" Ahorras: "),_c('b',[_vm._v(_vm._s(_vm.formatMoney(_vm.data.ahorras))+" ("+_vm._s(Math.round(_vm.data.descuento * 100))+"%)")])])]):_vm._e()]),(_vm.data.reviews_count)?_c('div',{staticClass:"card_area"},[_c('div',[_c('v-rating',{attrs:{"color":"#f5961d","background-color":"#f5961d","readonly":"","half-increments":"","hover":"","length":"5","size":"20","value":_vm.data.reviews_rating,"dense":""}}),(false)?_c('div',{staticStyle:{"margin-top":"0px"},attrs:{"id":"oldratingsystem"}},[_c('span',{class:{
                        fa: true,
                        'fa-star': true,
                        checked: _vm.data.reviews_rating >= 1,
                      }}),_c('span',{class:{
                        fa: true,
                        'fa-star': true,
                        checked: _vm.data.reviews_rating >= 2,
                      }}),_c('span',{class:{
                        fa: true,
                        'fa-star': true,
                        checked: _vm.data.reviews_rating >= 3,
                      }}),_c('span',{class:{
                        fa: true,
                        'fa-star': true,
                        checked: _vm.data.reviews_rating >= 4,
                      }}),_c('span',{class:{
                        fa: true,
                        'fa-star': true,
                        checked: _vm.data.reviews_rating >= 5,
                      }})]):_vm._e(),_c('div',{staticStyle:{"margin-top":"3px"}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.data.reviews_rating)+" ")]),_c('span',{staticStyle:{"font-size":"80%"}},[_vm._v("("+_vm._s(_vm.formatNumber(_vm.data.reviews_count))+" reviews)")])])])],1)]):_vm._e()])]),_c('tr',[_c('td',{staticStyle:{"font-size":"75%"},style:({
                color: _vm.getAgeColor(),
              })},[_vm._v(" Actualizado el "+_vm._s(new Date(_vm.data.updatedAt || _vm.data.date).toLocaleString())+" ")])])])])]):_c('div',{staticClass:"product_card",staticStyle:{"min-height":"250px"}},[_c('h3',[_vm._v(_vm._s(_vm.asin))])])]),_c('div',{staticStyle:{"text-align":"right","margin-right":"10px"}},[_c('a',{attrs:{"href":'https://keepa.com/iframe_addon.html#9-0-' + _vm.asin,"target":"_blank"}},[_vm._v("Keepa")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',[_c('tr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('colgroup',[_c('col',{staticStyle:{"width":"60%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"40%"},attrs:{"span":"1"}})])}]

export { render, staticRenderFns }
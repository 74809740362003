import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import AfiliacionView from '../views/AfiliacionView.vue'
import ListAsins from '../views/ListAsins.vue'
import TextEditor from '../views/TextEditor.vue'
import AuthView from '../views/AuthView.vue'

import MisasView from '@/views/misas/MisasView.vue'
import PrivacyPolicy from '@/views/other/PrivacyPolicy.vue'

import CodeIndexView from '@/views/code/CodeIndex.vue'
import CodeSublimeView from '@/views/code/CodeSublimeView.vue'
import IconsGenerator from '@/views/code/IconsGenerator.vue'
import CodeJavadocView from '@/views/code/JavadocView.vue'
import Typedef2Interface from '@/views/code/Typedef2Interface.vue'
import SecretShare from '@/views/code/SecretShare.vue'

import PregnacyView from '@/views/dates/PregnacyView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import TodosViewVue from '@/views/Tasks/TodosView.vue'
import TasksView from '@/views/Tasks/TasksView.vue'
import BarcodeScanner from '@/views/BarcodeScanner.vue'
import FoodView from '@/views/food/FoodView.vue'
import MenusView from '@/views/food/MenusView.vue'
import FoodDetail from '@/views/food/FoodDetail.vue'

import FriendsView from '@/views/friends/FriendsView.vue'

import GCalendarView from '@/views/gcalendar/GCalendarView.vue'

import QuotesView from '@/views/quotes/QuotesView.vue'
import AutonomoView from '@/views/autonomo/AutonomoView.vue'

import RocioView from '@/views/other/RocioView.vue'

import GroceriesView from '@/views/food/GroceriesView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/paraguas',
    name: 'afiliacion',
    component: AfiliacionView,
  },
  {
    path: '/groceries',
    name: 'groceries',
    component: GroceriesView,
  },
  {
    path: '/rocio-2024',
    name: 'RocioView',
    component: RocioView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/todos',
    name: 'TodosViewVue',
    component: TodosViewVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/facturas',
    name: 'AutonomoView',
    component: AutonomoView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/barcode',
    name: 'BarcodeScanner',
    component: BarcodeScanner,
  },
  {
    path: '/friends',
    name: 'FriendsView',
    component: FriendsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/quotes',
    name: 'QuotesView',
    component: QuotesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/food',
    name: 'FoodView',
    component: FoodView,
    meta: {
      requiresAuth: true,
      savePosition: true,
    },
  },
  {
    path: '/food/menus',
    name: 'MenusView',
    component: MenusView,
    meta: {
      requiresAuth: true,
      savePosition: true,
    },
  },
  {
    path: '/food/item/:id',
    name: 'FoodDetail',
    component: FoodDetail,
    props: true,
    meta: {
      requiresAuth: true,
      savePosition: false,
    },
  },
  // Google Calendar
  {
    path: '/gcalendar/',
    name: 'GCalendarView',
    component: GCalendarView,
    props: true,
    meta: {
      requiresAuth: true,
      savePosition: true,
    },
  },

  {
    path: '/tasks',
    name: 'TasksView',
    component: TasksView,
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/embarazo',
    name: 'embarazo',
    component: PregnacyView,
  },
  {
    path: '/list-asins',
    name: 'lists-asins',
    component: ListAsins,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/share-asins',
    redirect: 'list-asins',
  },
  {
    path: '/text',
    name: 'text',
    component: TextEditor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/misas',
    name: 'misas',
    component: MisasView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/privacidad',
    name: 'privacidad',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/code',
    name: 'Code',
    component: CodeIndexView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/code/sublime-autocompletion',
    name: 'CodeSublimeView',
    component: CodeSublimeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/code/javadoc',
    name: 'javadoc',
    component: CodeJavadocView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/code/typedef2interface',
    name: 'typedef2interface',
    component: Typedef2Interface,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/code/shamir',
    name: 'SecretShare',
    component: SecretShare,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/code/icons',
    name: 'icons-generator',
    component: IconsGenerator,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html

    if (to.matched.some((record) => record.meta.savePosition) && savedPosition) {
      //return savedPosition with some delay to let things load... maybe there is a better way
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 500)
      })
    }

    //If your browser supports scroll behavior, you can make it smooth:
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }

    //else, go to top

    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta?.requiresAuth) {
    const token = localStorage.token
    if (!token) next('/auth?redirect=' + to.path)
    else next()
  } else next()
})
export default router

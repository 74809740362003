<template>
  <v-card class="pa-0" height="500">
    <v-card-title class="pb-2">
      <v-text-field
        id="searchGroup"
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
      ></v-text-field>
      <div v-for="(v, k) in chips" :key="k" class="chips-container">
        <v-chip
          v-for="chip in v"
          :key="chip.value"
          class="mr-1 mb-1"
          small
          :outlined="filters[k] != chip.value"
          @click="filters[k] = filters[k] == chip.value ? '' : chip.value"
          >{{ chip.value || '???' }} ({{ chip.count }})</v-chip
        >
      </div>
    </v-card-title>
    <v-card-text class="pa-3 pt-0">
      <!--chips -->
      <div
        v-if="false"
        id="comida_picker::"
        style="
          background-color: #fafafa;
          padding: 0px 5px;
          margin: 0px 15px;
          border-bottom: 1px solid #ccc;
          border-top: 1px solid #ccc;
        "
      >
        <div
          @click="showFilters = !showFilters"
          style="width: 100%; cursor: pointer; display: flex; justify-content: space-between"
        >
          <div style="margin-top: 6px">Filtros</div>
          <v-btn icon text>
            <v-icon>{{ showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>
        <v-expand-transition>
          <div v-show="showFilters">
            <div style="margin-top: 4px">
              <div>
                <div style="margin-top: 4px">
                  <div v-for="(v, k) in chips" :key="k" class="chips-container">
                    <v-chip
                      v-for="chip in v"
                      :key="chip.value"
                      class="mr-1 mb-1"
                      small
                      :outlined="filters[k] != chip.value"
                      @click="filters[k] = filters[k] == chip.value ? '' : chip.value"
                      >{{ chip.value || '???' }} ({{ chip.count }})</v-chip
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>
      <div style="margin-top: 4px; margin-left: 15px">
        {{ filteredList.length + ' resultados' }}
      </div>
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="comida in filteredList"
            :key="comida.id"
            @click="save(comida.id)"
            :id="'comida-picker::' + comida.id"
            :style="{
              backgroundColor: comida.id == value ? 'rgba(144, 213, 240, 0.23)' : '',
            }"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ comida.title }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ comida.meal.join('/') }} | {{ comida.content }} |
                {{ comida.ana ? 'Ana' : 'No Ana' }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="error" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import utils from '@/assets/utils'
export default {
  props: {
    value: {
      type: String, //comida_id
      default: '',
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    value(n) {
      this.comida_id = n
      this.scrollToSelected()
    },
  },
  data() {
    return {
      searchText: '',
      comida_id: '',
      showFilters: false,
      filters: { order: '', meal: '' },
    }
  },
  computed: {
    chips() {
      let x = {}

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]

        for (const field in this.filters) {
          if (!x[field]) x[field] = []

          let v1 = item[field]
          if (!v1) v1 = 'None'
          let values = Array.isArray(v1) ? v1 : [v1]
          if (!values.length) values = ['None']

          for (let j = 0; j < values.length; j++) {
            const v = values[j]
            if (!x[field].find((a) => a.value == v)) x[field].push({ value: v, count: 0 })

            if (this.filteredList.find((a) => a.id == item.id)) {
              x[field].find((a) => a.value == v).count++
            }
          }
        }
      }

      return x
    },
    filteredList() {
      let x = []
      for (let i = 0; i < this.items.length; i++) {
        const food = this.items[i]

        let chipAdd = true
        for (const field in this.filters) {
          const filter = this.filters[field]
          if (!filter) continue
          let v1 = food[field]
          if (!v1) v1 = 'None'
          const v = Array.isArray(v1) ? v1 : [v1]
          if (!v.includes(filter) || (filter == 'None' && !v.length)) {
            chipAdd = false
          }
        }

        let searchAdd = false
        let text = [food.title, food.content, food.ana ? 'Ana' : '', food.order, food.meal.join(' ')].join(' ')
        if (utils.search.searchMatch(this.searchText, text)) {
          searchAdd = true
        }
        if (chipAdd && searchAdd) {
          x.push(food)
        }
      }
      return x
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save(id) {
      this.$emit('input', id)
      this.$emit('change', id)
      this.cancel() //just so we close
    },
    scrollToSelected() {
      console.log('SCROOOOOOOLL', this.items.find((x) => x.id == this.comida_id).title)
      setTimeout(() => {
        let x = document.getElementById('comida-picker::' + this.comida_id)
        if (x) {
          x.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 50)
    },
  },
  mounted() {
    this.comida_id = this.value
    if (this.comida_id) {
      this.scrollToSelected()
    }

    document.getElementById('searchGroup').focus()
  },
}
</script>

<style scoped>
.chips-container {
  margin-bottom: 2px;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

chips-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}
</style>

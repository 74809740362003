<template>
  <!-- TODO: add more inputs so we populate the manifest with that info -->
  <!-- code generated mostly by ChatGPT :) -->
  <div style="max-width: 800px; margin: auto">
    <div class="icon-generator">
      <b>1. Pick a file</b>
      <input type="file" @change="onFileChange" ref="fileInput" />
      <v-text-field
        type="text"
        v-model="imageUrl"
        outlined
        dense
        placeholder="Enter image URL (this may fail due to CORS)"
        @input="loadImageFromUrl"
      />
      <b>2. Set a background color: {{ backgroundColor }}</b>
      <input type="color" v-model="backgroundColor" />
      <v-btn depressed color="primary" @click="generateIcons">Generate Icons</v-btn>
      <a v-if="downloadUrl" :href="downloadUrl" download="web_app_icons.zip">Download Icons</a>
    </div>

    <div class="icon-previews">
      <div v-for="(v, name) in generatedImages" :key="name">
        <img :src="v.url" :alt="name" :height="v.size" :width="v.size" />
        <!--  :style="{ width: v.size + 'px', height: v.size + 'px' }"-->
        <p style="font-size: 80%">{{ name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import JSZip from 'jszip'
//import { saveAs } from 'file-saver'

export default {
  data() {
    return {
      imageUrl: '',
      imgElement: null,
      downloadUrl: null,
      backgroundColor: '#ffffff',
      generatedImages: {},
    }
  },
  methods: {
    async onFileChange(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.imageUrl = event.target.result
        this.loadImage()
      }
      reader.readAsDataURL(file)
    },
    async loadImageFromUrl() {
      if (this.imageUrl) {
        try {
          const response = await axios.get(this.imageUrl, { responseType: 'arraybuffer' })
          const base64 = Buffer.from(response.data, 'binary').toString('base64')
          const base64Url = `data:${response.headers['content-type']};base64,${base64}`
          this.imageUrl = base64Url
          //this.loadImage()
        } catch (error) {
          console.error('Error fetching image:', error)
          alert('Failed to fetch image from URL. Please make sure the URL is correct and try again.')
        }
      }
    },
    loadImage() {
      this.imgElement = new Image()
      this.imgElement.crossOrigin = 'Anonymous'
      this.imgElement.src = this.imageUrl
    },
    async generateIcons() {
      if (!this.imgElement) {
        alert('Please upload an image or enter a valid URL')
        return
      }

      //maskable means we will save as png instead of jpeg. we could have probably skipped this
      const sizes = [
        { size: 16, name: 'favicon-16x16.png', maskable: true },
        { size: 32, name: 'favicon-32x32.png', maskable: true },
        { size: 48, name: 'favicon-48x48.png', maskable: true },
        { size: 72, name: 'android-chrome-72x72.png', maskable: true },
        { size: 96, name: 'android-chrome-96x96.png', maskable: true },
        { size: 128, name: 'android-chrome-128x128.png', maskable: true },
        { size: 144, name: 'msapplication-icon-144x144.png', maskable: true },
        { size: 152, name: 'apple-touch-icon-152x152.png', maskable: true, apple: true },
        { size: 192, name: 'android-chrome-192x192.png', maskable: true },
        { size: 384, name: 'android-chrome-384x384.png', maskable: true },
        { size: 512, name: 'android-chrome-512x512.png', maskable: true },
      ]

      const zip = new JSZip()
      const iconsFolder = zip.folder('icons')
      this.generatedImages = {} // Reset the generatedImages object

      for (const { size, name, maskable, apple } of sizes) {
        const canvas = this.createCanvas(size)
        const ctx = canvas.getContext('2d')

        const sideLength = Math.min(this.imgElement.width, this.imgElement.height)
        const offsetX = (this.imgElement.width - sideLength) / 2
        const offsetY = (this.imgElement.height - sideLength) / 2

        ctx.drawImage(this.imgElement, offsetX, offsetY, sideLength, sideLength, 0, 0, size, size)
        if (apple) {
          // Remove transparency for Apple icons and replace with the selected background color
          ctx.fillStyle = this.backgroundColor
          ctx.globalCompositeOperation = 'destination-over'
          ctx.fillRect(0, 0, size, size)
          ctx.globalCompositeOperation = 'source-over'
        }

        const dataUrl = canvas.toDataURL(maskable ? 'image/png' : 'image/jpeg')
        this.generatedImages[name] = { size, url: dataUrl } // Store the base64 URL for preview
        const data = await this.dataUrlToBlob(dataUrl)
        iconsFolder.file(name, data, { binary: true })
      }

      // Add code snippets to a text file
      //TODO: update code so it is created matching the sizes array
      const manifestjson = `
        {
            "name": "Your App Name",
            "short_name": "App",
            "description": "A description of your app",
            "start_url": "/",
            "display": "standalone",
            "background_color": "#ffffff",
            "theme_color": "#000000",
            "icons":
            [
                {
                    "src": "icons/favicon-16x16.png",
                    "sizes": "16x16",
                    "type": "image/png"
                },
                {
                    "src": "icons/favicon-32x32.png",
                    "sizes": "32x32",
                    "type": "image/png"
                },
                {
                    "src": "icons/favicon-48x48.png",
                    "sizes": "48x48",
                    "type": "image/png"
                },
                {
                    "src": "icons/android-chrome-72x72.png",
                    "sizes": "72x72",
                    "type": "image/png"
                },
                {
                    "src": "icons/android-chrome-96x96.png",
                    "sizes": "96x96",
                    "type": "image/png"
                },
                {
                    "src": "icons/android-chrome-128x128.png",
                    "sizes": "128x128",
                    "type": "image/png"
                },
                {
                    "src": "icons/msapplication-icon-144x144.png",
                    "sizes": "144x144",
                    "type": "image/png"
                },
                {
                    "src": "icons/apple-touch-icon-152x152.png",
                    "sizes": "152x152",
                    "type": "image/png"
                },
                {
                    "src": "icons/android-chrome-192x192.png",
                    "sizes": "192x192",
                    "type": "image/png"
                },
                {
                    "src": "icons/android-chrome-384x384.png",
                    "sizes": "384x384",
                    "type": "image/png"
                },
                {
                    "src": "icons/android-chrome-512x512.png",
                    "sizes": "512x512",
                    "type": "image/png"
                }
            ]
        }

      `
      zip.file('manifest.json', manifestjson)

      //add code for index.html
      //TODO: update code it is dynamically generated from the sized array
      const indexhtml = `
      <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta name="theme-color" content="#000000">
            <link rel="manifest" href="/manifest.json">
            <link rel="icon" type="image/png" sizes="16x16" href="icons/favicon-16x16.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="48x48" href="icons/favicon-48x48.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="icons/android-chrome-72x72.png" />
            <link rel="apple-touch-icon" sizes="96x96" href="icons/android-chrome-96x96.png" />
            <link rel="apple-touch-icon" sizes="128x128" href="icons/android-chrome-128x128.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="icons/msapplication-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="icons/apple-touch-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="192x192" href="icons/android-chrome-192x192.png" />
            <link rel="apple-touch-icon" sizes="384x384" href="icons/android-chrome-384x384.png" />
            <link rel="apple-touch-icon" sizes="512x512" href="icons/android-chrome-512x512.png" />
            <title>Your App Name</title>
        </head>

        <body>
            <!-- Your app content here -->
        </body>
        </html>
      `
      zip.file('index.html', indexhtml)

      const zipBlob = await zip.generateAsync({ type: 'blob' })
      this.downloadUrl = URL.createObjectURL(zipBlob)
    },
    createCanvas(size) {
      const canvas = document.createElement('canvas')
      canvas.width = size
      canvas.height = size
      return canvas
    },
    async dataUrlToBlob(dataUrl) {
      const response = await axios.get(dataUrl, { responseType: 'blob' })
      return response.data
    },
  },
}
</script>

<style scoped>
.icon-generator {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.icon-previews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 1rem;
}

.icon-previews img {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
}
</style>

<template>
  <div>
    <v-btn depressed color="primary" @click="startScan">Scan Barcode</v-btn>
    <video id="barcodeVideo" ref="video" autoplay playsinline muted style="width: 100%; height: auto"></video>
    <div>{{ message }}</div>
    <v-btn depressed color="primary" @click="stopScan">Stop camera</v-btn>
  </div>
</template>

<script>
import Quagga from 'quagga'

export default {
  data() {
    return {
      videoStream: null,
      scannerEnabled: false,
      quaggaStarted: false,
      message: '',
    }
  },
  methods: {
    async startScan() {
      try {
        const video = document.getElementById('barcodeVideo')
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        video.srcObject = stream

        this.videoStream = stream

        this.initQuagga()
      } catch (error) {
        console.error('Failed to start camera: ', error)
      }
    },
    initQuagga() {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            //target: '#barcodeCanvas',
            target: this.videoStream,
          },
          decoder: {
            readers: ['ean_reader', 'upc_reader', 'code_128_reader'],
          },
        },
        function (err) {
          if (err) {
            console.error('Failed to initialize barcode scanner: ', err)
            return
          }
          console.log('Initialization finished. Ready to start')
          Quagga.start()
          this.quaggaStarted = true
        }
      )
      this.scannerEnabled = true
    },
    stopScan() {
      if (this.quaggaStarted) {
        Quagga.stop()
        Quagga.offDetected(this.onBarcodeDetected)
        this.quaggaStarted = false
      }
      this.scannerEnabled = false
      this.$refs.video.srcObject.getTracks().forEach((track) => {
        track.stop()
      })
      this.videoStream = null
    },
    onBarcodeDetected(result) {
      console.log(result)
      //TODO: Trigger your function here with the barcode data

      this.stopScan()
      this.message = result
    },
  },
  mounted() {
    Quagga.onDetected(this.onBarcodeDetected)
  },
  beforeDestroy() {
    if (this.quaggaStarted) {
      Quagga.stop()
      Quagga.offDetected(this.onBarcodeDetected)
    }
  },
}
</script>

<style>
video {
  transform: scaleX(-1);
}
</style>

<template>
  <div style="max-width: 500px; margin: 5px">
    <a :href="open_url" :target="'window-' + this.asin" style="color: inherit; text-decoration: inherit">
      <div v-if="asin && data && data.date" class="product_card">
        <h3>{{ data.title ? data.title.substr(0, 100) : 'Error' }}</h3>

        <table>
          <tbody>
            <tr></tr>
          </tbody>
          <colgroup>
            <col span="1" style="width: 60%" />
            <col span="1" style="width: 40%" />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div>
                  <img :src="tweakImage(data.image_url)" :alt="data.title" style="max-width: 100%" />
                </div>
              </td>
              <td min-width="200px">
                <div class="card_area" style="position: relative">
                  <div style="position: absolute; top: 3px; right: 3px; z-index: 99999"></div>
                  <div>
                    <span style="font-size: 185%">{{ formatMoney(data.current_price) }}</span>
                    &nbsp;<span
                      class="strikeout"
                      style="font-size: 90%"
                      v-if="data.old_price && data.old_price != 'n/a'"
                      >{{ formatMoney(data.old_price) }}</span
                    >
                  </div>
                  <div v-if="data.descuento && data.descuento != 'n/a'" style="margin-top: 8px">
                    <span style="font-size: 80%">
                      Ahorras:
                      <b>{{ formatMoney(data.ahorras) }} ({{ Math.round(data.descuento * 100) }}%)</b></span
                    >
                  </div>
                </div>
                <div class="card_area" v-if="data.reviews_count">
                  <div>
                    <v-rating
                      color="#f5961d"
                      background-color="#f5961d"
                      readonly
                      half-increments
                      hover
                      length="5"
                      size="20"
                      :value="data.reviews_rating"
                      dense
                    ></v-rating>
                    <div id="oldratingsystem" v-if="false" style="margin-top: 0px">
                      <span
                        :class="{
                          fa: true,
                          'fa-star': true,
                          checked: data.reviews_rating >= 1,
                        }"
                      ></span>
                      <span
                        :class="{
                          fa: true,
                          'fa-star': true,
                          checked: data.reviews_rating >= 2,
                        }"
                      ></span>
                      <span
                        :class="{
                          fa: true,
                          'fa-star': true,
                          checked: data.reviews_rating >= 3,
                        }"
                      ></span>
                      <span
                        :class="{
                          fa: true,
                          'fa-star': true,
                          checked: data.reviews_rating >= 4,
                        }"
                      ></span>
                      <span
                        :class="{
                          fa: true,
                          'fa-star': true,
                          checked: data.reviews_rating >= 5,
                        }"
                      ></span>
                    </div>
                    <div style="margin-top: 3px">
                      <span
                        ><b>{{ data.reviews_rating }} </b
                        ><span style="font-size: 80%">({{ formatNumber(data.reviews_count) }} reviews)</span></span
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style="font-size: 75%"
                :style="{
                  color: getAgeColor(),
                }"
              >
                Actualizado el
                {{ new Date(data.updatedAt || data.date).toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="product_card" style="min-height: 250px">
        <h3>{{ asin }}</h3>
      </div>
    </a>
    <div style="text-align: right; margin-right: 10px">
      <a :href="'https://keepa.com/iframe_addon.html#9-0-' + asin" target="_blank">Keepa</a>
    </div>
  </div>
</template>

<script>
//import { DateTime } from 'luxon'

export default {
  props: {
    asin: {
      type: String,
      default: '',
    },
  },
  watch: {
    asin() {
      this.fetchData()
    },
  },

  data() {
    return {
      data: {},
      tries: 0,
    }
  },
  computed: {
    sessionId() {
      let x = sessionStorage.refreshTime
      if (!x) {
        sessionStorage.refreshTime = new Date().getTime()
      }
      return x
    },
    open_url() {
      let u = 'https://www.amazon.es/dp/' + this.asin + '?tag=compramejores-21&linkCode=ogi&th=1&psc=1'
      return u
    },
  },

  methods: {
    /*utcToLocal(d) {
      //return DateTime.fromISO(d).toLocal().toJSDate()
      let dd = new Date(d)
      return DateTime.fromJSDate(dd)
        .setZone('utc', { keepLocalTime: true })
        .setZone('local', { keepLocalTime: false })
        .toJSDate()
    },*/
    formatMoney(number) {
      return number.toLocaleString('es-ES', {
        style: 'currency',
        currency: 'EUR',
      })
    },
    formatNumber(number) {
      let n = +number
      return isNaN(n) ? n : n.toLocaleString('de-DE')
    },
    getAgeColor() {
      let date = this.data.updatedAt ? this.data.updatedAt : this.data.date
      let ageHours = Math.abs((new Date() - new Date(date)) / 36e5)
      if (ageHours > 12) return 'red'
      if (ageHours > 5) return 'orange'
      return ''
    },
    openAsin() {
      window.open(this.open_url, 'window-' + this.asin)
    },
    tweakImage(url) {
      if (!url) return url
      return url.replace('_AC_SY300_', '_SCLZZZZZZZ_AC_SR250,200_')
    },
    async fetchData() {
      const url =
        'https://useful-tools-34.s3.eu-west-3.amazonaws.com/cmdata/' + this.asin + '-es.json?s=' + this.sessionId
      let x = await fetch(url)
      if (x.ok) {
        let jj = await x.json()
        jj.date = new Date(new Date(jj.date).getTime() + 2 * 60 * 60 * 1000)
        this.data = jj
        this.$emit('gotData', jj)
        return
      }

      this.tries++
      const maxtries = 4
      if (this.tries < maxtries) {
        console.log(`Retry fetch (try ${this.tries}/${maxtries})`)
        setTimeout(() => {
          this.fetchData()
        }, 1000 + this.tries)
      }

      this.data = undefined
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.product_card {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}
.asin_card2 {
  background-color: white;
  max-width: 500px;
  margin: 8px;

  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.strikeout {
  font-size: 4em;
  line-height: 1em;
  position: relative;
}
.strikeout::after {
  border-bottom: 0.125em solid gray;
  content: '';
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 0.6;
}

.v-main__wrap {
  background-color: lightblue;
}

.filterr {
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border: solid lightgray 1px;
  border-radius: 5px;
  font-size: 12px;
}

.card_area {
  margin-bottom: 10px;
  padding: 10px;
  /*border: solid lightgray 1px;*/
  /*border-radius: 5px;*/
  font-size: 1rem;
}

/*
.container {
  margin-left:  20px;
  margin-right: 20px;
}
*/

/** */
.checked {
  color: orange;
}

body {
  font-size: 20px;
  font-family: calibri;
  padding: 20px;
}

div.products_wrapper {
  width: 100%;
  display: flex;

  /*justify-content: space-between;*/
  flex-wrap: wrap;
  /* background-color: #007cba; ;*/
  padding-top: 0px;

  margin: auto;

  justify-content: center;
}

h3 {
  padding: 5px;
  margin-bottom: 0px;
}

img {
  padding: 5px;
}

div.products_wrapper div.product_card {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;

  padding: 5px;

  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  border: 1px solid #e3e3e3;
  /*border-radius: var(--imageBorderRadius);*/
  border-radius: 3px;
  overflow: wrap;
  /*width: calc(((100vw - 80px - 260px - 128px) / 4));*/
  /*width: calc(((100vw - 260px - 80px - 64px) / 2));*/
  /*width: calc(
      (100% - 10px * 4) / 2
      );*/
  /* 100% of the div, minus margins*4, and /2 (so at min, 2 posters fit the width)*/
  max-width: min(700px, 100%);
  width: max(440px, 25vw);

  /*min-width: 10px;*/
  background-color: white;
  cursor: pointer;
}

div.products_wrapper div.product_card table {
  text-align: center;

  /*margin: auto;*/
  margin: 0;
}

div.products_wrapper div.product_card h3 {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
  font-weight: normal;
  font-size: 1.2rem;
}

div.products_wrapper div.product_card table td {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 0px;
  line-height: 0.9;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .product_card {
    width: 90vw !important;
    max-width: 90vw !important;
  }
  .product_card img {
    max-width: 45vw !important;
  }
}
</style>

<template>
  <div>
    <v-textarea rows="1" auto-grow label="quote" v-model="item.quote" outlined></v-textarea>
    <v-text-field v-model="item.author" label="author" outlined></v-text-field>
    <v-textarea rows="1" label="comments" auto-grow v-model="item.comments" outlined color="primary"></v-textarea>
    <v-textarea rows="1" outlined auto-grow v-model="item.tags" placeholder="#tag1 #tag2" label="tags"></v-textarea>
    <div class="code">
      {{ item }}
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      item: {
        quote: '',
        author: '',
        updatedAt: '',
        updatedBy: '',
        createdAt: '',
        comments: '',
      },
    }
  },
  watch: {
    item: {
      deep: true,
      handler(newValue) {
        console.log('changed', newValue)
        this.$emit('change', newValue)
      },
    },
  },
  methods: {
    async fetchQuote() {
      let x = await api.apiFetch('/quotes/get/' + this.id, {})
      if (x.ok) {
        this.item = await x.json()
      } else {
        console.warn('No suh quote', this.id)
      }
    },
  },
  mounted() {
    this.fetchQuote()
  },
}
</script>

<style scoped>
.code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 75%;
  line-height: 1;
  padding: 5px;
  margin-top: 10px;
}
</style>

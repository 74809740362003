<template>
  <div>
    <span class="modern-link" @click="preview = !preview">{{ preview ? (text ? 'Edit' : 'Add text') : 'Done' }}</span>

    <div v-if="preview">
      <div v-html="html"></div>
    </div>
    <div v-else>
      <div style="display: flex">
        <v-btn text depressed @click="surround('**')">B</v-btn>
        <v-btn text depressed @click="surround('_')"><i>I</i></v-btn>
        <v-btn text depressed @click="prepend('# ')">H1</v-btn>
        <v-btn text depressed @click="prepend('## ')">H2</v-btn>
        <v-btn text depressed @click="prepend('### ')">H3</v-btn>
      </div>
      <v-textarea id="mysupertextarea" outlined rows="4" auto-grow hide-details v-model="text"></v-textarea>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      text: '',
      html: '',
      preview: true,
    }
  },
  watch: {
    preview() {
      this.html = marked.parse(this.text)
    },
    value(newValue) {
      this.text = newValue
    },
    text(newValue) {
      this.$emit('input', newValue)
    },
  },
  computed: {
    /*htmlContent() {
      return marked(this.text)
    },*/
  },

  methods: {
    prepend(char) {
      const textarea = document.getElementById('mysupertextarea')

      // Get the current selection start position and text
      const start = textarea.selectionStart
      const text = textarea.value

      // Find the start position of the current line
      let lineStart = start
      while (lineStart > 0 && text.charAt(lineStart - 1) !== '\n') {
        lineStart--
      }

      let newText = ''
      if (text.substring(lineStart, lineStart + char.length) == char) {
        // Remove the character from the beginning of the line
        newText = text.substring(0, lineStart) + text.substring(lineStart + char.length)
      } else {
        // Insert the character at the beginning of the line
        newText = text.substring(0, lineStart) + char + text.substring(lineStart)
      }
      this.text = newText
      textarea.value = newText

      // Set the cursor position to after the added character
      const newCursorPos = start + char.length
      textarea.selectionStart = newCursorPos
      textarea.selectionEnd = newCursorPos
      textarea.focus()
    },
    surround(chr) {
      const textarea = document.getElementById('mysupertextarea')

      // Get the current selection
      let start = textarea.selectionStart
      let end = textarea.selectionEnd
      if (end > 0 && textarea.value.substring(end - 1, end) == ' ') end--
      if (textarea.value.substring(start, start + 1) == ' ') start++
      const selectedText = textarea.value.substring(start, end)

      // Add the characters before and after the selection
      const newText = textarea.value.substring(0, start) + chr + selectedText + chr + textarea.value.substring(end)

      // Update the text area with the new text
      this.text = newText
      textarea.value = newText

      // Set the selection to include the added characters
      textarea.selectionStart = start
      textarea.selectionEnd = end + 2 * chr.length
      textarea.focus()
    },
  },
  mounted() {
    this.text = this.value
    this.preview = this.text
  },
}
</script>

<style lang="scss" scoped></style>












































import Vue from 'vue'
import api from '@/assets/api'

export default Vue.extend({
  data() {
    return {
      key: 'value',
    }
  },
  computed: {
    routes() {
      return api.userAppRoutes()
    },
  },
})

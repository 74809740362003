function findAsin(text: string): Array<string> {
  const regex = /\b[A-Z0-9]{10}\b/g
  const asins: Array<string> = [] //no idea why using const here... got error otherwise. didn't now const could change :/

  text.match(regex)?.forEach((asin) => {
    if (!asins.includes(asin)) asins.push(asin)
  })

  return asins
}

export default { findAsin }

<template>
  <div style="margin-top: 10px">
    <v-row style="width: 100%; display: flex; justify-content: center">
      <!-- Posts List-->
      <v-col v-if="false" style="max-width: 200px">
        <v-text-field label="Buscar" outlined dense prepend-inner-icon="mdi-magnify"></v-text-field>
        <v-list>
          <v-list-item link v-for="(post, index) in posts" :key="index">
            <v-list-item-title>
              {{ post.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>

      <!-- preview -->
      <v-col style="max-width: 400px; overflow-wrap: break-word; height: 100%">
        <div
          style="
            border: 1px solid black;
            border-radius: 5px;
            padding: 10px;
            height: 100%;
            max-height: 100%;
            overflow-y: scroll;
          "
          v-html="buildHtml"
        ></div>
      </v-col>

      <!-- Text Editor -->
      <v-col style="max-width: 800px">
        <v-text-field
          label="Título"
          @change="titleChanged"
          dense
          counter="65"
          outlined
          v-model="data.title"
          :rules="[(x) => (x.length > 70 ? 'Cuidado: Google corta después de unos 70 caracteres.' : true)]"
          clearable
        ></v-text-field>
        <v-text-field
          style="font-size: 85%"
          @input="data.slug = sanitizeSlug(data.slug)"
          label="Slug"
          prefix="test.com/"
          dense
          counter
          outlined
          clearable
          v-model="data.slug"
        ></v-text-field>

        <div style="display: flex">
          <v-text-field
            disabled
            label="Imagen principal"
            dense
            counter
            outlined
            clearable
            v-model="data.featured_image"
          ></v-text-field>

          <v-btn depressed class="ml-1">
            <v-icon>mdi-image</v-icon>
          </v-btn>
          <v-btn depressed class="ml-1">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-textarea
          label="Extracto"
          outlined
          clearable
          counter="150"
          v-model="data.excerpt"
          :rules="[(x) => (x.length > 160 ? 'Cuidado: Google corta después de approx 160 caracteres' : true)]"
          rows="4"
        >
        </v-textarea>

        <!-- Format controls -->
        <div style="display: flex; overflow-x: auto">
          <v-btn small depressed class="mr-1">
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
          <v-btn small depressed class="mr-1">
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>

          <v-btn small depressed class="mr-1">
            <v-icon>mdi-format-underline</v-icon>
          </v-btn>

          <v-btn small depressed class="mr-1">
            <v-icon>mdi-format-quote-open</v-icon>
          </v-btn>

          <v-select
            dense
            outlined
            hide-details
            :items="['Normal', 'Título 2', 'Título 3', 'Título 4', 'Título 5', 'Título 6']"
          >
          </v-select>

          <v-btn small depressed class="mr-1">
            <v-icon>mdi-image</v-icon>
          </v-btn>
          <v-btn small depressed class="mr-1">
            <v-icon>mdi-cart-plus</v-icon>
          </v-btn>
          <v-btn small depressed class="mr-1" @click="preview = !preview">
            <v-icon v-if="!preview">mdi-eye</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </div>

        <v-textarea
          label="Contenido"
          counter
          outlined
          style="width: 100%; padding: 5px"
          rows="10"
          v-model="data.content"
        ></v-textarea>
      </v-col>

      <!-- Metadata -->
      <v-col style="max-width: 300px">
        <v-select label="Autor" outlined dense :items="authors" v-model="data.author"></v-select>

        <v-select label="Categorías" outlined multiple dense :items="categories" v-model="data.categories"></v-select>

        <v-select label="Etiquetas" outlined multiple dense :items="tags" v-model="data.tags"></v-select>

        <v-select label="Estado" outlined dense :items="statuses" v-model="data.status"></v-select>

        <p style="text-align: center" v-if="data.date">
          <b>{{ data.date + ' ' + pad(data.hour) + ':' + pad(data.minute) }} </b>
        </p>

        <v-date-picker v-model="data.date" no-title locale="es-es"></v-date-picker>
        <!-- time -->
        <div style="display: flex">
          <v-text-field
            label="Hora"
            outlined
            @focus="$event.target.select()"
            dense
            type="number"
            min="0"
            max="23"
            v-model="data.hour"
          ></v-text-field>
          <v-text-field
            label="Minuto"
            @focus="$event.target.select()"
            outlined
            dense
            type="number"
            min="0"
            max="60"
            v-model="data.minute"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <!-- text editor component -->
    <div>
      <h2>Text editor v2 (coded by chatgpt)</h2>
      <TextEditorVue v-model="data.content" />
    </div>

    <v-btn disabled block>Guardar</v-btn>

    {{ data }}
  </div>
</template>

<script>
import TextEditorVue from '@/components/TextEditor.vue'

export default {
  components: {
    TextEditorVue,
  },
  data() {
    return {
      preview: false,
      authors: [{ text: 'Josega', value: 1 }],
      categories: [
        { text: 'Noticias', value: 1 },
        { text: 'Religión', value: 2 },
      ],
      tags: [{ text: 'Chulísimo', value: 1 }],

      statuses: [
        { text: 'Borrador', value: 'draft' },
        { text: 'Publicado', value: 'publish' },
      ],

      posts: [
        { title: 'My primer post', excerpt: 'Es un ejemplo en realidad' },
        { title: 'My SEGUNDO post', excerpt: 'Otro ejemplo fake' },
      ],
      data: {
        title: '',
        slug: '',
        excerpt: '',
        content: '',
        featured_image: '',
        categories: [],
        tags: [],
        status: 'draft',
        type: 'post',
        author: 1,
        date: '',
        hour: 0,
        minute: 0,
      },
    }
  },
  computed: {
    buildHtml() {
      let html = ''

      let content = this.data.content

      //Format paragraphs
      content = content.replace(/\n(.*)\n/g, '\n<p>$1</p>\n')

      //Parse author/duration
      const author = 'Alex Sopeña'
      content = content.replace(
        /\[ *autor.*\]/g,
        `
          <span style="font-size:80%">
          <img height="12" width="12" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMiAwYzYuNjIzIDAgMTIgNS4zNzcgMTIgMTJzLTUuMzc3IDEyLTEyIDEyLTEyLTUuMzc3LTEyLTEyIDUuMzc3LTEyIDEyLTEyem04LjEyNyAxOS40MWMtLjI4Mi0uNDAxLS43NzItLjY1NC0xLjYyNC0uODUtMy44NDgtLjkwNi00LjA5Ny0xLjUwMS00LjM1Mi0yLjA1OS0uMjU5LS41NjUtLjE5LTEuMjMuMjA1LTEuOTc3IDEuNzI2LTMuMjU3IDIuMDktNi4wMjQgMS4wMjctNy43OS0uNjc0LTEuMTE5LTEuODc1LTEuNzM0LTMuMzgzLTEuNzM0LTEuNTIxIDAtMi43MzIuNjI2LTMuNDA5IDEuNzYzLTEuMDY2IDEuNzg5LS42OTMgNC41NDQgMS4wNDkgNy43NTcuNDAyLjc0Mi40NzYgMS40MDYuMjIgMS45NzQtLjI2NS41ODYtLjYxMSAxLjE5LTQuMzY1IDIuMDY2LS44NTIuMTk2LTEuMzQyLjQ0OS0xLjYyMy44NDggMi4wMTIgMi4yMDcgNC45MSAzLjU5MiA4LjEyOCAzLjU5MnM2LjExNS0xLjM4NSA4LjEyNy0zLjU5em0uNjUtLjc4MmMxLjM5NS0xLjg0NCAyLjIyMy00LjE0IDIuMjIzLTYuNjI4IDAtNi4wNzEtNC45MjktMTEtMTEtMTFzLTExIDQuOTI5LTExIDExYzAgMi40ODcuODI3IDQuNzgzIDIuMjIyIDYuNjI2LjQwOS0uNDUyIDEuMDQ5LS44MSAyLjA0OS0xLjA0MSAyLjAyNS0uNDYyIDMuMzc2LS44MzYgMy42NzgtMS41MDIuMTIyLS4yNzIuMDYxLS42MjgtLjE4OC0xLjA4Ny0xLjkxNy0zLjUzNS0yLjI4Mi02LjY0MS0xLjAzLTguNzQ1Ljg1My0xLjQzMSAyLjQwOC0yLjI1MSA0LjI2OS0yLjI1MSAxLjg0NSAwIDMuMzkxLjgwOCA0LjI0IDIuMjE4IDEuMjUxIDIuMDc5Ljg5NiA1LjE5NS0xIDguNzc0LS4yNDUuNDYzLS4zMDQuODIxLS4xNzkgMS4wOTQuMzA1LjY2OCAxLjY0NCAxLjAzOCAzLjY2NyAxLjQ5OSAxIC4yMyAxLjY0LjU5IDIuMDQ5IDEuMDQzeiIvPjwvc3ZnPg==">
            ${author}&nbsp;&nbsp;
            <img height="12 width="12" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMiAwYzYuNjIzIDAgMTIgNS4zNzcgMTIgMTJzLTUuMzc3IDEyLTEyIDEyLTEyLTUuMzc3LTEyLTEyIDUuMzc3LTEyIDEyLTEyem0wIDFjNi4wNzEgMCAxMSA0LjkyOSAxMSAxMXMtNC45MjkgMTEtMTEgMTEtMTEtNC45MjktMTEtMTEgNC45MjktMTEgMTEtMTF6bTAgMTFoNnYxaC03di05aDF2OHoiLz48L3N2Zz4=">
            4 min
          </span>
        `
      )

      // Parse teinteresa
      content = content.replace(
        /\[ *teinteresa.*\]/g,
        `
      <a href="">
        <div style="border:1px solid gray; border-radius: 5px; display:flex; margin: 20px 0px; position:relative"  >
        <span style="position:absolute; top:-10px; left:10px; background-color:white;font-size:80%; padding: 0px 4px">Te interesa</span>

        <p style="font-size:90%; font-weight: bold; padding: 10px;">
        Ut venenatis sem et aliquet posuere</p>
        <img src="https://sm.ign.com/ign_es/news/b/brazilian-/brazilian-spider-man-no-way-home-trailer-seems-to-include-a_bk8c.jpg" style="height:80px"></img>
        </div>
        </a>

      `
      )

      //parse markdown
      content = content.replace(/\*\*(((?!\*).)*)\*\*/g, '<strong>$1</strong>')

      //Parse images
      content = content.replace(/!\[(.*)\]\[(.*)\]/g, "<img alt='$1' src='$2' style='max-width:100%'></img>")

      html += `<h1 contenteditable>${this.data.title}</h1>`
      html += `<p contenteditable>${this.data.excerpt}</p>`
      html += `<p contenteditable>${content}</p>`

      return html
    },
  },
  watch: {
    /*
    data: {
      deep: true,
      handler() {
        let d = JSON.parse(JSON.stringify(this.data))
        d.slug = d.slug = 'asdf' //.replace(/ /gi, '-')
        this.data = JSON.parse(JSON.stringify(d))
      },
    },
    */
  },
  methods: {
    pad(x) {
      return +x < 10 ? '0' + x : x
    },
    titleChanged() {
      if (!this.data.slug) {
        this.data.slug = this.sanitizeSlug(this.data.title)
      }
    },
    sanitizeSlug(text) {
      let x = text
      x = x
        .toLowerCase()
        .replace(/á/g, 'a')
        .replace(/é/g, 'e')
        .replace(/í/g, 'i')
        .replace(/ó/g, 'o')
        .replace(/ú/g, 'u')
        .replace(/ñ/g, 'n')
        .replace(/ /g, '-')
        .replace(/[^\w\d-]/g, '') //remove everything else
      return x
    },
  },
}
</script>

<style lang="scss" scoped>
.bbtn {
  padding: 3px 5px;
  background-color: #ccc;
  margin: 2px;
}
</style>

<template>
  <div style="max-width: 900px; margin: auto">
    <h1>My Tasks <span class="modern-link" @click="fetchData">Refresh List</span></h1>

    <!-- serch text -->
    <div>
      <v-text-field
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
        class="mb-1"
      ></v-text-field>
    </div>

    <!-- filters -->
    <div>
      <!-- filters 1-->
      <div>
        <ChipDropdown
          :items="['createdAt', 'updatedAt', 'eta', 'internal_eta', 'focus_date', 'title', 'rank', 'priority']"
          label="sort by"
          v-model="sortBy"
        />
        <!-- sort order -->
        <ChipDropdown :items="['asc', 'desc']" label="" v-model="sortOrder" />

        <!-- status -->
        <ChipDropdown :items="['completed', 'pending', 'all']" label="" v-model="showStatus" />
      </div>
      <!-- filters 2 -->
      <div style="margin-top: 4px">
        <!-- hide -->

        <ChipDropdown :items="['focus_date', 'completedAt', 'createdAt']" label="focus" v-model="focusField" />
        <ChipDropdown :items="['week', 'day', 'all']" label="" v-model="focusPeriod" />

        <ChipDate v-model="focusDate" label="" />
        <ChipDropdown :items="['Yes', 'No']" label="Carry Over" v-model="carryOver" />
      </div>

      <!-- filter by topic -->
      <div style="margin-top: 10px">
        <v-chip
          v-for="topic in topics"
          :key="topic.name"
          :outlined="filterTopic != topic.name"
          @click="filterTopic = filterTopic == topic.name ? null : topic.name"
          small
          :disabled="!topic.value"
          class="ml-1 mt-1"
          >{{ topic.name }} ({{ topic.value }})</v-chip
        >
      </div>

      <!-- hashtags -->
      <div style="margin-top: 10px">
        <v-chip
          v-for="hashtag in hashtags"
          :key="hashtag.name"
          :outlined="filterHastag != hashtag.name"
          @click="filterHastag = filterHastag == hashtag.name ? null : hashtag.name"
          small
          :disabled="!hashtag.value"
          class="ml-1 mt-1"
          >{{ hashtag.name }} ({{ hashtag.value }})</v-chip
        >
      </div>
    </div>

    <!-- items -->
    <div style="margin-top: 10px">
      <div style="display: flex; justify-content: space-between">
        <p>
          {{ filteredList.length }} results <br />
          effort: {{ total_effort }}h
        </p>
        <div>
          <v-btn
            text
            @click="focusDate = new Date(new Date(focusDate).getTime() + -1 * 86400000).toISOString().slice(0, 10)"
            >-1</v-btn
          >
          <v-btn text @click="onTodayClicked">today</v-btn>
          <v-btn
            text
            @click="focusDate = new Date(new Date(focusDate).getTime() + +1 * 86400000).toISOString().slice(0, 10)"
            >+1</v-btn
          >
        </div>
      </div>
      <div v-if="filteredList.length > 0">
        <div v-for="item in filteredList" :key="item.id">
          <TaskCard :task="item" :isCritical="true" v-if="false" />
          <div class="todo-item" v-if="true">
            <div>
              <v-checkbox :input-value="item.completed" @click="toggleCheck(item)" class="mt-0"></v-checkbox>
            </div>

            <div @click="onClick(item.id)" style="width: 100%">
              <strong>{{ item.title }}</strong>

              <p style="font-size: 80%; color: gray; word-break: break-all">
                {{ item.description }}
              </p>
              <div style="font-size: 70%; display: flex; justify-content: space-between">
                <span :style="{ fontWeight: 'bold', color: timeLeft(item) < 1 && !item.completed ? 'red' : '' }"
                  >ETA: {{ format(min_eta(item), 'ccc dd MMM') }}
                </span>
                <span style="font-weight: bold">
                  Effort: {{ item.effort_hours ? item.effort_hours : '??' }}h | Priority:
                  {{ item.priority === undefined ? '??' : item.priority }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!loading" style="margin-top: 50px">
        <p v-if="carryOver == 'Yes'">Nothing left! 🥳</p>
        <p v-else>Looking good! But make sure you don't have carry overs!</p>
      </div>
    </div>

    <!-- buffer -->
    <div style="height: 60px"></div>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- edit -->
    <v-dialog v-model="showForm" max-width="500" scrollable persistent>
      <TodoFormVue :id="editId" :isnew="!items.map((x) => x.id).includes(editId)" @saved="saved" @canceled="canceled" />
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import api from '@/assets/api'
import utils from '@/assets/utils'
import TodoFormVue from './TodoForm.vue'
import TaskCard from './TaskCard.vue'
import ChipDropdown from '@/components/ChipDropdown.vue'
import ChipDate from '@/components/ChipDate.vue'

export default {
  components: {
    TodoFormVue,
    TaskCard,
    ChipDropdown,
    ChipDate,
  },
  data() {
    return {
      searchText: '',
      loading: false,
      editId: false,
      showForm: false,
      sortBy: 'internal_eta',
      sortOrder: 'asc',
      filterTopic: null,

      filterHastag: null,
      showStatus: 'pending',
      focusField: 'focus_date',
      focusPeriod: 'day',
      focusDate: new Date().toISOString().slice(0, 10),
      carryOver: 'Yes',

      items: [],
      newTodo: {
        id: '',
      },
    }
  },
  watch: {
    showStatus(n) {
      if (n == 'pending') {
        this.focusField = 'focus_date'
        this.carryOver = 'Yes'
      }
      if (n == 'completed') {
        this.focusField = 'completedAt'
        this.carryOver = 'No'
      }
    },
    /*
    focusField(n) {
      if (n == 'completedAt') {
        this.showStatus = 'completed'
        this.carryOver = 'No'
      } else if (n == 'focus_date') {
        this.showStatus = 'pending'
        this.carryOver = 'Yes'
      } else if (n == 'createdAt') {
        this.showStatus = 'all'
        this.carryOver = 'No'
      }
    },*/
    focusDate(n) {
      if (n == new Date().toISOString().slice(0, 10)) {
        this.carryOver = 'Yes'
      } else {
        this.carryOver = 'No'
      }
    },
    focusPeriod(n, o) {
      if (n == 'all') this.focusDate = null
      if (o == 'all') this.focusDate = new Date().toISOString().slice(0, 10) //let's focus on today when moving away from 'all'
    },
    sortBy(n) {
      if (n == 'eta' || n == 'internal_eta' || n == 'focus_date') {
        this.sortOrder = 'asc'
      } else {
        this.sortOrder = 'desc'
      }
    },
    editId(newValue) {
      if (newValue) {
        this.showForm = true
      } else {
        this.showForm = false
      }
    },
  },
  computed: {
    total_effort() {
      let total = 0
      for (let i = 0; i < this.filteredList.length; i++) {
        const item = this.filteredList[i]
        if (item.effort_hours) total = total + +item.effort_hours
      }
      return Math.round(total * 10) / 10
    },
    topics() {
      let topics = []
      for (let i = 0; i < this.items2.length; i++) {
        const item = this.items2[i]
        if (item.completed) continue

        let topic = this.getTopic(item)
        if (!topics.find((x) => x.name == topic)) topics.push({ name: topic, value: 0 })

        //count only if visible after applying hashtags
        let visibility = item.visibility
        if (
          visibility.visibleByStatus &&
          visibility.visibleByFocus &&
          visibility.visibleBySearch &&
          visibility.visibleByHashtag
        ) {
          //all by topic
          topics.find((x) => x.name == topic).value++
        }
      }
      return topics.sort((a, b) => b.value - a.value)
    },

    hashtags() {
      let ht = []
      for (let i = 0; i < this.items2.length; i++) {
        const item = this.items2[i]
        if (item.completed) continue
        let hashtags = this.findHashtags(item)
        for (let j = 0; j < hashtags.length; j++) {
          const hashtag = hashtags[j]
          if (!ht.find((x) => x.name == hashtag)) ht.push({ name: hashtag, value: 0 })

          //count only if visible after applying topics
          let visibility = item.visibility
          if (
            visibility.visibleByStatus &&
            visibility.visibleByFocus &&
            visibility.visibleBySearch &&
            visibility.visibleByTopic
          ) {
            //all by hashtag
            ht.find((x) => x.name == hashtag).value++
          } else {
            //nothing
          }
        }
      }
      ht = ht.sort((a, b) => b.value - a.value)
      return ht
    },
    filteredList() {
      return this.items2.filter((x) => x.visible == true)
    },
    items2() {
      if (!this.items) return []
      let output = []
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i]

        //backfill predecessors and antecessors
        if (!item.predecessors) item.predecessors = []
        if (!item.successors) item.successors = []

        let visibleByStatus = true
        if (this.showStatus == 'completed' && !item.completed) visibleByStatus = false
        if (this.showStatus == 'pending' && item.completed) visibleByStatus = false

        //focus v2

        let visibleByFocus = true
        if (this.focusPeriod != 'all') {
          if (!item[this.focusField]) {
            visibleByFocus = false
          } else {
            let cd = DateTime.fromJSDate(new Date(this.focusDate))
            let cw = cd.weekNumber
            let fd = DateTime.fromJSDate(new Date(item[this.focusField].split('T')[0]))
            let fw = fd.weekNumber
            let delta = fd.diff(cd, 'days').as('days')

            visibleByFocus = false
            if (this.focusPeriod == 'week' && cw == fw) visibleByFocus = true //strict this week
            if (this.focusPeriod == 'day' && delta == 0) visibleByFocus = true //strict toda

            if (this.carryOver == 'Yes') {
              if (this.focusPeriod == 'week' && cw >= fw && !item.completed) visibleByFocus = true //show week carry overs
              if (this.focusPeriod == 'day' && delta < 0 && !item.completed) visibleByFocus = true //sow day carry overs
            }
          }
        }

        //filter topic
        let visibleByTopic = true
        if (this.filterTopic) {
          let topic = this.getTopic(item)
          if (topic != this.filterTopic) {
            visibleByTopic = false
          }
        }

        //search
        let visibleBySearch = true
        if (this.searchText) {
          if (!utils.search.searchMatch(this.searchText, [item.title, item.description].join(' '))) {
            visibleBySearch = false
          }
        }

        //hashtags
        let visibleByHashtag = true
        if (this.filterHastag) {
          visibleByHashtag = false
          if (this.findHashtags(item).length && this.findHashtags(item).includes(this.filterHastag)) {
            visibleByHashtag = true
          }
        }

        item.visibility = { visibleByStatus, visibleByFocus, visibleByTopic, visibleBySearch, visibleByHashtag }
        item.visible = visibleByStatus && visibleByFocus && visibleByTopic && visibleBySearch && visibleByHashtag

        output.push(item)
      }

      if (this.sortBy) {
        let sortType = ['rank', 'priority'].includes(this.sortBy) ? 'numeric' : 'text'
        if (this.sortOrder == 'asc') {
          output.sort((a, b) => {
            if (sortType == 'numeric') {
              return (a[this.sortBy] ? +a[this.sortBy] : Infinity) - (b[this.sortBy] ? b[this.sortBy] : Infinity)
            } else {
              return a[this.sortBy] ? (a[this.sortBy] + a.priority).localeCompare(b[this.sortBy] + b.priority) : 0
            }
          })
        } else {
          output.sort((a, b) => {
            if (sortType == 'numeric') {
              return (b[this.sortBy] ? +b[this.sortBy] : -Infinity) - (a[this.sortBy] ? a[this.sortBy] : -Infinity)
            } else {
              return b[this.sortBy] ? (b[this.sortBy] + a.priority).localeCompare(a[this.sortBy] + b.priority) : 0
            }
          })
        }
      }

      return output
    },
  },
  methods: {
    onTodayClicked() {
      this.focusDate = new Date().toISOString().slice(0, 10)

      //also do some reset:
      this.focusField = 'focus_date'
      this.focusPeriod = 'day'
      this.carryOver = 'Yes'
      this.showStatus = 'pending'
      this.sortBy = 'internal_eta'
      this.filterTopic = null
      this.filterTag = null
    },

    min_eta(item) {
      let m_eta = ''
      if (item.internal_eta) m_eta = item.internal_eta
      if (item.eta) m_eta = item.eta

      return m_eta
    },
    format(d, format) {
      return DateTime.fromJSDate(new Date(d)).toFormat(format)
    },
    getTopic(item) {
      const regex = /^(?:\[(.+?)\] |- )/
      const str = item.title ? item.title : ''
      const match = regex.exec(str)

      const topic = match ? match[1] : 'No Topic'
      return topic
    },

    findHashtags(item) {
      const text = ((item.title || '') + (item.description || '')).toLowerCase()
      const regex = /#\w+/g
      const hashtags = text.match(regex)
      return hashtags || []
    },
    calculateCriticalPath(tasks) {
      // Step 1: Calculate the earliest start (ES) and earliest finish (EF) times for each task
      const calculateEarliestTimes = (task) => {
        if (task.ES === undefined) {
          task.ES =
            task.predecessors.length === 0
              ? 0
              : Math.max(
                  ...task.predecessors.map((p) => {
                    calculateEarliestTimes(p)
                    return p.EF
                  })
                )
          task.EF = task.ES + task.duration
        }
      }

      tasks.forEach((task) => calculateEarliestTimes(task))

      // Step 2: Calculate the latest start (LS) and latest finish (LF) times for each task
      const calculateLatestTimes = (task) => {
        if (task.LF === undefined) {
          task.LF =
            task.successors.length === 0
              ? task.EF
              : Math.min(
                  ...task.successors.map((s) => {
                    calculateLatestTimes(s)
                    return s.LS
                  })
                )
          task.LS = task.LF - task.duration
        }
      }

      tasks.forEach((task) => calculateLatestTimes(task))

      // Step 3: Identify the critical path
      const criticalPath = tasks.filter((task) => task.ES === task.LS)

      return criticalPath
    },
    timeLeft(item) {
      let eta = this.min_eta(item)
      let dt2 = DateTime.fromJSDate(new Date(eta))
      let dt1 = DateTime.now()

      return dt2.diff(dt1, 'days').as('days')
    },
    async toggleCheck(item) {
      item.completed = !item.completed
      this.loading = true
      let x = await api.apiFetch('/todos/put', {
        method: 'POST',
        body: JSON.stringify(item),
      })
      this.loading = false
      if (x.ok) {
        console.log('Saved')
      } else {
        alert('ouch. not saved')
      }
    },
    onClick(id) {
      this.editId = id
      this.showForm = true
    },
    saved() {
      this.showForm = false
      this.editId = null
      this.fetchData()
    },
    canceled() {
      this.showForm = false
    },
    getRandomId() {
      const randomId = Math.random().toString(36).substr(2, 9)
      return randomId
    },
    addNewItem() {
      console.log('New item')
      // Add logic to add new item to your data source here
      // Reset form fields and close dialog
      this.editId = null
      this.editId = this.getRandomId()
      this.showForm = true
    },
    async fetchData() {
      this.loading = true
      let x = await api.apiFetch('/todos/getall')
      if (x.ok) {
        this.items = await x.json()
      }
      this.loading = false
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.todo-item {
  margin: auto;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  max-width: 500px;
  display: flex;
}
</style>

<template>
  <div>
    <h1>Javadoc typedef helper</h1>

    <p>Paste here your JSON, we'll give you the @typedef</p>
    <v-textarea outlined v-model="input"></v-textarea>

    <CodeEditor v-model="output" syntax="js" />

    <AdSenseFeed />
  </div>
</template>

<script>
import AdSenseFeed from '@/components/AdSenseFeed.vue'
import CodeEditor from '@/components/CodeEditor.vue'
export default {
  components: {
    CodeEditor,
    AdSenseFeed,
  },
  data() {
    return {
      input: '{"key":"value"}',
    }
  },
  computed: {
    output() {
      let input = {}
      try {
        input = JSON.parse(this.input)
      } catch {
        return 'Error. Invalid JSON'
      }

      if (Array.isArray(input)) {
        if (!input.length) return "This doesn't work with empty"
      }

      /**
       * Function to handle in a loop each item on the input json. Should have used https://www.npmjs.com/package/json-schema-to-jsdoc instead of bothering with this...
       */
      let handleItem = (item, parent, wasArray = false) => {
        let output = ''

        //null or undfined
        if (!item) {
          output += `\n * @property {*} ${parent} ${JSON.stringify(item)}`
          return output
        }

        //array: handle and take first item as the "item"
        if (Array.isArray(item)) {
          output += `\n * @property {${typeof item[0]}[]} ${parent} ${JSON.stringify(item)}`
          output += handleItem(item[0], parent, true) //we take the first object by default //TODO: we could review all objects to build a new item with all the possible keys of the children
          return output
        }
        //handle each object key
        if (typeof item === 'object') {
          if (parent && !wasArray) {
            output += `\n * @property {${typeof item}} ${parent} ${JSON.stringify(item)}`
          }
          for (const key in item) {
            if (Object.hasOwnProperty.call(item, key)) {
              let path = parent ? parent + '.' + key : key
              output += handleItem(item[key], path)
              /*if (typeof item2 === 'object') {
                output += handleItem(item2, key)
              }*/
            }
          }
          return output
        }

        //if not returned so far
        output += `\n * @property {${typeof item}} ${parent} ${JSON.stringify(item)}`

        return output
      }

      let output = ''
      if (Array.isArray(input)) {
        output = `/**\n * @typedef {${typeof input[0]}[]} Type1`
        if (typeof input[0] != 'object') {
          output += '\n*/'
          return output //no need to continue if basic type (string, number)
        }
        input = input[0]
      } else if (typeof input === 'object') {
        output = '/**\n * @typedef {object} Type1'
      }
      output += handleItem(input, '')
      output += '\n*/'
      return output

      /*
        if (typeof x == 'object'){

        }else if (typeof x == 'Array'){
          return ""
        }
        */
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <!-- Sublime autocompletion -->

    <div style="margin-bottom: 5px">
      <span class="modern-link" @click="tab = 'form'">Form</span>
      |
      <span class="modern-link" @click="tab = 'json'">JSON</span>
    </div>

    <v-row v-if="tab == 'form'">
      <v-col cols="12" md="4">
        <v-text-field outlined dense clearable class="consolas" v-model="item.trigger" label="trigger"></v-text-field>
        <v-text-field
          outlined
          dense
          clearable
          class="consolas"
          v-model="item.annotation"
          label="annotation"
        ></v-text-field>
        <v-text-field outlined dense clearable class="consolas" v-model="item.details" label="details"></v-text-field>
        <v-text-field
          outlined
          dense
          clearable
          class="consolas"
          v-model="item.group"
          label="group"
          hint="You may use group1.group2 to create folders in this site"
        ></v-text-field>

        <v-row>
          <v-col>
            <v-select outlined dense :items="kinds" label="kind.type" class="consolas" v-model="item.kind[0]">
            </v-select>
          </v-col>
          <v-col>
            <v-text-field outlined dense :items="kinds" label="kind.letter" class="consolas" v-model="item.kind[1]">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field outlined dense label="kind.tooltip" class="consolas" v-model="item.kind[2]"> </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              class="consolas"
              outlined
              rows="2"
              label="notes"
              placeholer="further notes (source, context...)"
              v-model="item.notes"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <CodeEditor v-model="item.contents" :syntax="syntax" />
        <v-textarea
          v-if="false"
          outlined
          clearable
          class="consolas"
          v-model="item.contents"
          @keydown.tab.prevent="tabber($event)"
          auto-grow
          rows="10"
          placeholder="contents"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row v-if="tab == 'json'">
      <v-col cols="12">
        <v-textarea
          class="consolas"
          style="font-size: 90%"
          outlined
          v-model="jsonString"
          placeholder="JSON string"
        ></v-textarea>
      </v-col>
    </v-row>
    <hr />
    <br />
    <!-- preview -->
    <div style="display: flex">
      <div id="sub-completion-preview">
        <table
          class="consolas"
          style="font-weight: bold; font-size: 100%; color: #d9d9d9; background-color: #3e474f; margin: 4px"
        >
          <tr>
            <td
              :style="{
                width: '25px',
                textAlign: 'center',
                color: getKindColor(item.kind[0]),
                backgroundColor: getKindColor(item.kind[0]) + '33',
              }"
            >
              {{ item.kind ? item.kind[1] : '' }}
            </td>
            <td style="width: 200px">
              {{ item.trigger }}
            </td>
            <td style="width: 90px; text-align: right; font-style: italic; color: #989898">
              {{ item.annotation }}
            </td>
          </tr>
          <tr>
            <td colspan="3" style="background-color: #2f373f" v-html="item.details"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CodeEditor from '../../../../components/CodeEditor.vue'
export default {
  components: {
    CodeEditor,
  },
  props: {
    value: {
      type: Object,
      default() {
        return { scope: '', components: [] } //TODO: {"trigger": "", "contents": ""}
      },
    },
  },
  data() {
    return {
      tab: 'form', //form|json
      syntax: 'sql', //so far, it may be 'sql' or 'js'
      item: {
        trigger: '',
        annotation: '',
        details: '',
        contents: '',
        kind: 'snippet',
        group: 'other',
      },

      kinds: ['ambiguous', 'function', 'keyword', 'markup', 'namespace', 'navigation', 'snippet', 'type', 'variable'],

      jsonString: '{"trigger": "", "contents": ""}',
    }
  },

  created() {
    this.jsonString = JSON.stringify(this.item)
  },

  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.item = newValue
      },
    },
    item: {
      deep: true,
      handler(newValue) {
        if (!newValue.kind) newValue.kind = 'snippet'
        if (this.isString(newValue.kind)) {
          newValue.kind = [newValue.kind, newValue.kind.charAt(0), newValue.kind]
        }
        this.jsonString = JSON.stringify(newValue)
        this.$emit('input', newValue)
      },
    },
    jsonString(newValue) {
      let x = JSON.parse(newValue)
      let t = x.trigger
      if (t && t.includes('\t')) {
        x.annotation = t.split('\t')[1]
        x.trigger = t.split('\t')[0]
      }

      if (!x.kind) x.kind = 'snippet'

      this.item = x
    },
  },
  methods: {
    isString(x) {
      return typeof x === 'string' || x instanceof String
    },
    getKindColor(k) {
      let x = {
        ambiguous: 'darkblue',
        function: '#ec5f66',
        keyword: '#ec5f96',
        markup: '#f9ae57',
        namespace: '#6699cc',
        navigation: '#f9cb56',
        snippet: '#8cc294',
        type: '#5fa7a9',
        variable: '#5fa7a9',
      }
      return x[k]
    },
    tabber(event) {
      if (event) {
        event.preventDefault()
        let currentText = this.item.contents
        let startText = currentText.slice(0, event.target.selectionStart)
        let endText = currentText.slice(event.target.selectionStart)
        currentText = `${startText}\t${endText}`
        this.item.contents = currentText
        event.target.selectionEnd = event.target.selectionStart + 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.consolas {
  font-family: 'Courier New', Courier, monospace;
  font-size: 80%;
}
td {
  padding: 5px;
}
</style>

<template>
  <div>
    <div>
      {{ queso }}
    </div>

    <v-btn @click="fetchData()">Click</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queso: { get: 'h' },
    }
  },
  methods: {
    async fetchData() {
      const url =
        'https://misas.org/api/parishsearch?sortbypos=[-3.645863,40.468406,68]&country=es&date=2022/12/29&masses=1&pos=[-3.645863,40.468406,1500]'
      let x = await fetch(url, {
        mode: 'no-cors',
      })
      console.log('[alex] queso 1', x)

      let j = await x.json()
      console.log('[alex] queso 1', j)
      this.queso = j
    },
  },
}
</script>

<style lang="scss" scoped></style>

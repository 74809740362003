<template>
  <div>
    <v-btn @click="enableNotifications">Enable notifications</v-btn>
    <br />
    <br />
    <v-btn @click="disableNotifications">Disable notifications</v-btn>

    <div>status: {{ enabled }}</div>
  </div>
</template>

<script>
import api from '@/assets/api'
export default {
  data() {
    return {
      serverKey: 'BCNF83QGDG9YYFbH_5QUjn_HdeIGbP3cUihdlr00513Rhhap2kHybtVQC7P2o-pat9qftkFY21bSg_yD7ydh1S0',
    }
  },
  computed: {
    enabled() {
      return Notification.permission
    },
  },
  methods: {
    async enableNotifications() {
      const serverKey = this.serverKey
      //In your Vue app, add the following code to request permission to show notifications and register a service worker:
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(function (permission) {
          if (permission !== 'granted') {
            alert("You won't receive any notifications")
          } else {
            alert('You enabled notifications')
          }
        })
      }

      //register
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./serviceWorker.js').then(function () {
          console.log('Service worker registered')
        })
      }

      //In your Vue app, add the following code to subscribe to push notifications and send the subscription to your backend:
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: serverKey,
            })
            .then(function (subscription) {
              console.log('Push subscription created', subscription)
              // TODO: Send the subscription to your backend

              api
                .apiFetch('/notifications/save-subscription', {
                  method: 'POST',
                  body: JSON.stringify(subscription),
                })
                .then((x) => {
                  if (x.ok) {
                    x.json().then((j) => {
                      alert(JSON.stringify(j))
                    })
                  }
                })
            })
            .catch(function (error) {
              console.error('Push subscription error', error)
            })
        })
      }
    },
    disableNotifications() {
      //TODO: this doesn't work
      console.log('Trying to disable notifications (current: ' + Notification.permission + ')')

      if (Notification.permission === 'granted') {
        Notification.requestPermission().then(function (permission) {
          if (permission === 'denied') {
            alert('Notification permission revoked')
          }
        })
      } else {
        alert("You didn't have permissions to revoke")
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <!--
      <h1>Code</h1> 
    

    <v-tabs style="margin-bottom: 10px">
      <v-tab>Sublime Completions</v-tab>
    </v-tabs>
    -->

    <div v-if="tab == 0">
      <SublimeComponent />
    </div>
  </div>
</template>

<script>
import SublimeComponent from './components/SublimeCompletion/ComponetsList.vue'
export default {
  components: {
    SublimeComponent,
  },
  data() {
    return {
      tab: 0,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div>
      <v-alert type="error"
        >SSS is not that secure... We should instead use multi-sig instead where possible. <br />See
        https://blog.keys.casa/shamirs-secret-sharing-security-shortcomings/
      </v-alert>
      <v-text-field label="secret" v-model="secret"></v-text-field>
      <v-btn @click="split">split</v-btn>

      <v-textarea v-model="mnemonicsString"> </v-textarea>

      <v-btn @click="combine">restore</v-btn>
    </div>

    <pre>

      {{ JSON.stringify(sharesArray, null, 2) }}
    </pre>
  </div>
</template>

<script>
import * as bip39 from 'bip39'
import secrets from 'secrets.js-34r7h'
//import secrets from 'secrets.js-grempe'
//important: it is possible to make "secrets.js-grempe" work by updating its secrets.js file as per: https://github.com/grempe/secrets.js/issues/20#issuecomment-674290906, but moved to `secrets.js-34r7h` as it seems to have it built-in

import { Buffer } from 'buffer'
// Assign the Buffer object to the global scope
if (typeof window !== 'undefined') {
  window.Buffer = Buffer
}

export default {
  data() {
    return {
      secret: '',

      mnemonicsString: '',
      shares: 3,
      threshold: 2,
      sharesArray: [],
    }
  },
  methods: {
    async split() {
      let shares = this.generateShares(this.secret, this.shares, this.threshold)
      console.log('shares', shares)
      let mnemonics = await this.sharesToMnemonics(shares)
      console.log('mnemonics', mnemonics)
      this.mnemonicsString = mnemonics.join('\n')
    },
    async combine() {
      let mnemonics = this.mnemonicsString.split('\n')
      let shares = await this.mnemonicsToShares(mnemonics)
      console.log('shares', shares)
      let secret = this.reconstructSecret(shares)
      console.log('secret', secret)
    },

    // Step 2: Generate shares
    generateShares(secret, totalShares, threshold) {
      secrets.init()
      //const hexSecret = secrets.str2hex(secret) // convert secret to hex
      const shares = secrets.share(this.stringToEntropy(secret), totalShares, threshold)
      return shares
    },

    // Step 3: Convert shares to mnemonics
    async sharesToMnemonics(shares) {
      let mnemonics = []
      for (let share of shares) {
        console.log('share ', share)
        /*let bytes = secrets.hex2str(share) // convert hex to string to get bytes
        console.log('bytes1', bytes)*/
        let bytes = this.stringToEntropy(share)
        console.log('bytes2', bytes)
        const mnemonic = bip39.entropyToMnemonic(bytes)
        mnemonics.push(mnemonic)
      }
      return mnemonics
    },

    stringToEntropy(inputString) {
      const encoder = new TextEncoder()
      const stringBytes = encoder.encode(inputString)
      console.log('stringBytes'.stringBytes)

      const n = 128
      const entropyBytes = new Uint8Array(n) // 128 bits = 16 bytes
      const entropyBytes2 = new Uint8Array(n) // 128 bits = 16 bytes
      console.log('entropyBytes2', entropyBytes2)
      entropyBytes.set(stringBytes.slice(0, n)) // Copy the first 16 bytes

      return entropyBytes
    },

    // Step 4: Mnemonic to share
    async mnemonicsToShares(mnemonics) {
      let shares = []
      for (let mnemonic of mnemonics) {
        const bytes = bip39.mnemonicToEntropy(mnemonic)
        const share = secrets.str2hex(bytes) // convert bytes to hex for shares
        shares.push(share)
      }
      return shares
    },

    // Step 5: Reconstruct the secret
    reconstructSecret(shares) {
      const secretHex = secrets.combine(shares)
      const secret = secrets.hex2str(secretHex) // convert hex to string
      return secret
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div style="max-width: 500px; margin: 5px">
    <div v-if="asin && data && data.date" class="product_card">
      <div class="product_img">
        <a :href="open_url" :target="'window-' + this.asin" style="color: inherit; text-decoration: inherit">
          <img
            :src="tweakImage(data.image_url)"
            :alt="data.title"
            :style="{
              maxWidth: '100%',
              opacity: getAgeColor() == '' ? '100%' : '20%',
              mixBlendMode: 'multiply',
            }"
          />
        </a>
      </div>

      <div class="product_detail">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <div style="display: flex; align-items: center">
            <span class="price2">{{ formatMoney(data.current_price) }}</span>
            <div class="discount2">{{ Math.round(data.descuento * 100) }}%</div>
          </div>

          <div style="display: flex; align-items: center">
            <v-rating
              color="#f5961d"
              background-color="#f5961d"
              readonly
              hover
              length="1"
              size="20"
              :value="1"
              dense
            ></v-rating>

            <div style="text-align: middle">
              <span>
                <span class="stars2">
                  {{ data.reviews_rating }}
                </span>

                <span style="font-size: 80%" v-if="false">({{ formatNumber(data.reviews_count) }} reviews)</span></span
              >
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="false"
        style="font-size: 75%"
        :style="{
          color: getAgeColor(),
        }"
      >
        Actualizado el
        {{ new Date(data.updatedAt || data.date).toLocaleString() }}
      </div>
    </div>

    <a v-else :href="open_url" :target="'window-' + this.asin" style="color: inherit; text-decoration: inherit">
      <div class="product_card">
        <div class="product_img">
          <h3>{{ asin }}</h3>
        </div>
        <div class="product_detail"></div>
      </div>
    </a>
  </div>
</template>

<script>
//import { DateTime } from 'luxon'

export default {
  props: {
    asin: {
      type: String,
      default: '',
    },
  },
  watch: {
    asin() {
      this.fetchData()
    },
  },

  data() {
    return {
      data: {},
      tries: 0,
    }
  },
  computed: {
    sessionId() {
      let x = sessionStorage.refreshTime
      if (!x) {
        sessionStorage.refreshTime = new Date().getTime()
      }
      return x
    },
    open_url() {
      let u = 'https://www.amazon.es/dp/' + this.asin + '?tag=compramejores-21&linkCode=ogi&th=1&psc=1'
      return u
    },
  },

  methods: {
    /*utcToLocal(d) {
      //return DateTime.fromISO(d).toLocal().toJSDate()
      let dd = new Date(d)
      return DateTime.fromJSDate(dd)
        .setZone('utc', { keepLocalTime: true })
        .setZone('local', { keepLocalTime: false })
        .toJSDate()
    },*/
    formatMoney(number) {
      return number.toLocaleString('es-ES', {
        style: 'currency',
        currency: 'EUR',
      })
    },
    formatNumber(number) {
      let n = +number
      return isNaN(n) ? n : n.toLocaleString('de-DE')
    },
    getAgeColor() {
      let date = this.data.updatedAt ? this.data.updatedAt : this.data.date
      let ageHours = Math.abs((new Date() - new Date(date)) / 36e5)
      if (ageHours > 12) return 'red'
      if (ageHours > 5) return 'orange'
      return ''
    },
    openAsin() {
      window.open(this.open_url, 'window-' + this.asin)
    },
    tweakImage(url) {
      if (!url) return url
      return url.replace('_AC_SY300_', '_SCLZZZZZZZ_AC_SR250,250_')
    },
    async fetchData() {
      const url =
        'https://useful-tools-34.s3.eu-west-3.amazonaws.com/cmdata/' + this.asin + '-es.json?s=' + this.sessionId
      let x = await fetch(url)
      if (x.ok) {
        let jj = await x.json()
        jj.date = new Date(new Date(jj.date).getTime() + 2 * 60 * 60 * 1000)
        this.data = jj
        this.$emit('gotData', jj)
        return
      }

      this.tries++
      const maxtries = 4
      if (this.tries < maxtries) {
        console.log(`Retry fetch (try ${this.tries}/${maxtries})`)
        setTimeout(() => {
          this.fetchData()
        }, 1000 + this.tries)
      }

      this.data = undefined
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.product_card {
  /*background-color: white;*/
  border: 1px solid #e3e3e3;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(200, 200, 200, 0.1); /** fcfcfc | f8f8f8 */
  margin-bottom: 1rem;
}

.product_img {
  height: 230px;
  width: 230px;
  margin: auto;
  margin-bottom: 8px;
}

.product_detail {
  height: 20px;
}

.price2 {
  font-size: 100%;
}

.discount2 {
  font-weight: bold;
  padding: 2px 4px;
  background-color: rgb(186, 0, 0);
  border-radius: 5px;
  color: white;

  font-size: 70%;
  margin-left: 7px;
}

.stars2 {
  font-size: 85%;
  font-weight: 500;
}

h3 {
  padding: 5px;
  margin-bottom: 0px;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .product_card {
  }

  .product_img {
    height: 130px;
    width: 130px;
  }

  .product_detail {
    height: 20px;
  }
}
</style>

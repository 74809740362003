import { render, staticRenderFns } from "./AsinCard2.vue?vue&type=template&id=51910c22&scoped=true&"
import script from "./AsinCard2.vue?vue&type=script&lang=js&"
export * from "./AsinCard2.vue?vue&type=script&lang=js&"
import style0 from "./AsinCard2.vue?vue&type=style&index=0&id=51910c22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51910c22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VRating})

<template>
  <div class="task-card" :class="{ critical: isCritical }">
    <h3 class="task-title">{{ task.title }}</h3>
    <p class="task-description">{{ task.description }}</p>
    <div class="task-info">
      <span class="task-status">{{ status }}</span>
      <span class="task-date">{{ formatDate(task.eta) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: Object,
    isCritical: Boolean,
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
  },
  data() {
    return {
      status: 'PENDING',
    }
  },
}
</script>

<style scoped>
.task-card {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.task-card.critical {
  border: 2px solid red;
}

.task-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.task-description {
  color: #666;
  margin-bottom: 1rem;
}

.task-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-status {
  background-color: #007bff;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.task-date {
  font-size: 0.9rem;
  font-weight: 600;
}
</style>

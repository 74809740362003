<template>
  <v-menu offset-y :close-on-content-click="true" :nudge-width="40" max-width="290px">
    <template #activator="{ on, attrs }">
      <v-chip outlined v-bind="attrs" v-on="on" class="ml-1 mt-1">
        <span v-if="label">{{ label }}:&nbsp;</span> {{ finalLabel }}
        <v-icon class="ml-1" small right> mdi-chevron-down </v-icon>
      </v-chip>
    </template>
    <v-card>
      <v-date-picker v-model="selected" no-title @input="updateDate"></v-date-picker>
      <v-card-actions>
        <v-btn text color="primary" @click="setToday"> Today </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="setTomorrow"> Tomorrow </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ChipDatePicker',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    finalLabel() {
      let datee = this.selected
      let today = new Date().toISOString().slice(0, 10)
      let tomorrow = new Date(Date.now() + 86400000).toISOString().slice(0, 10)
      let yesterday = new Date(Date.now() - 86400000).toISOString().slice(0, 10)
      if (datee == today) datee = 'Today'
      if (datee == tomorrow) datee = 'Tomorrow'
      if (datee == yesterday) datee = 'Yesterday'
      return datee || 'Pick a date'
    },
  },
  data() {
    return {
      selected: this.value || '',
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    },
  },
  methods: {
    updateDate(date) {
      this.$emit('input', date)
    },
    setToday() {
      const today = new Date().toISOString().slice(0, 10)
      this.updateDate(today)
    },
    setTomorrow() {
      const tomorrow = new Date(Date.now() + 86400000).toISOString().slice(0, 10)
      this.updateDate(tomorrow)
    },
  },
}
</script>

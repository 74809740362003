<template>
  <div>
    <h1>Typedef to Interface</h1>

    <p>Paste here your typedef, we'll give you the @typedef</p>
    <v-textarea outlined v-model="input"></v-textarea>

    {{ output }}
    <CodeEditor v-model="output" syntax="js" />

    <div>
      <div v-for="(err, index) in errors" :key="index" style="color: red">{{ err }}</div>
    </div>

    <AdSenseFeed v-if="false" />
  </div>
</template>

<script>
import AdSenseFeed from '@/components/AdSenseFeed.vue'
import CodeEditor from '@/components/CodeEditor.vue'
export default {
  components: {
    CodeEditor,
    AdSenseFeed,
  },
  data() {
    return {
      input: `/**\n * @typedef {object} TypeName\n * @property {object} data "anobject"\n * @property {string} data.id "anId"\n*/`,
      output: 'asdf',
      errors: [],
    }
  },
  computed: {},
  watch: {
    input() {
      this.output = ''
      this.output = this.convert()
    },
  },
  methods: {
    convert() {
      console.log('converting')
      let output = ''
      let input = '' + this.input

      let lines = input.split('\n')


      let prev_levels = []
      let opened = 0
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]

        console.log('line', line)

        if (i == 0 || i == lines.length - 1) continue //skip
        else if (i == 1) {
          const match = line.match(/@typedef\s+{\w+}\s+([\w\d]+)/)
          console.log('matvh', match)
          if (!match) {
            this.errors.push("couldn't find typedef name")
            return
          }
          output = `export interface ${match[1]} {`
          continue;
        }

        else {
          const match = line.match(/@property\s+{(\w+)}\s+([\w\d.]+)\s*(.*)?/)

          let type = match[1]
          let key = match[2]
          let levels = key.split(".")
          let n = levels.length

          let spaces = " ".repeat(n*2)

          console.log("M", match)
          if (match.length==4){
            output+=`\n${spaces}/** ${match[3]} */`
          }

          if (type=='object'){
            output+=`\n${spaces}${key}: {`
            opened++;

          }else {
            key = key.split(".")[key.split(".").length-1]
            output+=`\n${spaces}${key}: ${type};`
          }
          if (n < prev_levels.length){
            output+=`${spaces}}\n`
            opened--
          }
          prev_levels = levels
        }


      }
      output+=`\n}`
      return output
    },
  },
}
</script>

<style lang="scss" scoped></style>

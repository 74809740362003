<template>
  <div>
    <div style="display: flex; justify-content: space-between; max-width: 500px">
      <FormDatePicker outlined v-model="dt.date" :label="label" />
      <div v-if="!dt.allday">
        <v-text-field v-model="dt.time" dense type="time" outlined></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import FormDatePicker from '@/components/FormDatePicker.vue'
export default {
  components: {
    FormDatePicker,
  },
  props: {
    label: { type: String, default: 'Date' },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    allday: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    allday(nv) {
      this.dt.allday = nv
    },
    value: {
      deep: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) == JSON.stringify(oldValue)) return false
        if (newValue.date || newValue.dateTime) {
          let str = newValue.date ? newValue.date : newValue.dateTime
          let dt1 = DateTime.fromISO(str, { zone: newValue.timeZone })
          this.dt.date = dt1.toFormat('yyyy-MM-dd')
          this.dt.time = dt1.toFormat('HH:mm')
          this.dt.allday = this.allday
          this.dt = { ...this.dt }
        }
      },
    },
    dt: {
      deep: true,
      handler(nv) {
        let output = {}
        if (nv.allday) {
          output = {
            date: nv.date,
          }
        } else {
          output = {
            dateTime: DateTime.fromISO(nv.date + 'T' + nv.time, { zone: this.timeZone }).toISO(),
            timeZone: this.timeZone,
          }
        }
        console.log('input: ' + JSON.stringify(output))
        this.$emit('input', output)
      },
    },
  },

  data() {
    return {
      timeZone: 'Europe/Madrid',
      dt: {
        date: new Date().toISOString().substring(0, 10),
        time: '00:00',
        timeZone: 'Europe/Madrid',
        allday: false,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
